<template>
	<v-checkbox v-bind="$attrs" v-on="$listeners" v-model="innerValue" :loading="loading"
		:disabled="$attrs['disabled'] || loading" @change="onChange"/>
</template>

<script>
export default {
	props: {
		value: Boolean,
		callback: Function,
	},
	data() {
		return {
			innerValue: this.value,
			loading: false,
		}
	},
	methods: {
		async onChange(state) {
			if (this.loading) return;
			this.loading = true;
			this.innerValue = await (this.callback)(state);
			this.loading = false;
		},
	},
}
</script>