<template>
	<my-expand title="Уреди в имота" :loading="loading">
		<v-simple-table dense>
			<thead>
			<tr>
				<th>
					Тип,
					Номер,
					Локация
				</th>
				<th>
					Модел, Радио N
				</th>
				<th>Пломба</th>
				<th>Монтаж</th>
				<th>Демонтаж</th>
				<th>Дата за подмяна</th>
				<th>wpl_id</th>
				<th class="text-right">Фактор</th>
				<th class="text-right">Мощност</th>
				<th>Описание</th>

				<th class="text-right px-2">
					<device-edit-popup insert :imot="imot" @reload="$emit('reload')" />
				</th>
			</tr>
			</thead>
			<tbody>
			<tr v-for="device in devices" :key="device.d.id">
				<td>
					{{ device.d.device_N }}<br>
					<span class="grey--text">{{ DeviceTypeLabels.get(device.d.device_type_code) }} - {{ device.d.room }}</span>
				</td>
				<td>
					{{ device.m.brand }} {{ device.m.model }}
					<div>
						<span v-if="device.m.is_radio=='1'">
							<v-icon small color="green" style="vertical-align: bottom;" title="Радио">mdi-wifi</v-icon>
							{{ device.d.radio_N }}
						</span>
					</div>
				</td>
				<td>{{ device.d.plomba_N }}</td>
				<td>
					<div><date-format :date="device.d.mount_date" /></div>
					<div class="grey--text">{{ device.d.mount_value }}</div>
				</td>
				<td>
					<div><date-format :date="device.d.unmount_date" /></div>
					<div class="grey--text">{{ device.d.unmount_value }}</div>
				</td>
				<td><date-format :date="device.d.reinstall_date" /></td>
				<td>{{ device.d.wpl_id }}</td>
				<td class="text-right">{{ device.d.radiator_factor }}</td>
				<td class="text-right">{{ device.d.radiator_power }}</td>
				<td>{{ device.d.radiator_description }}</td>
				<td class="text-right px-2" nowrap>
					<v-tooltip top v-if="device.d.notes">
						<template v-slot:activator="{attr,on}">
							<v-btn icon small v-bind="attr" v-on="on">
								<v-icon dense>mdi-information-outline</v-icon>
							</v-btn>
						</template>
						{{ device.d.notes }}
					</v-tooltip>
					<v-btn v-if="!locked && !device.dd.id" icon small title="Добави в сесията" @click="$emit('add', device.d.id)">
						<v-icon dense color="purple lighten-2">mdi-arrow-up-circle</v-icon>
					</v-btn>
					<v-icon v-else dense>mdi-blank</v-icon>
					<device-edit-popup
						title="Уред - копирай като нов"
						insert
						:copy="device.d"
						:imot="imot"
						@reload="$emit('reload')"
					/>
					<device-edit-popup
						:device="device.d.id"
						:session="session"
						@reload="$emit('reload')"
					/>
				</td>
			</tr>
			</tbody>
		</v-simple-table>
	</my-expand>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */

import DeviceEditPopup from '../../ittijs/components/DeviceDataList/DeviceEditPopup.vue'
import MyExpand from "@/ittijs/components/MyExpand";
import {DeviceTypeLabels} from "../../ittijs/Nomenclatures";
import DateFormat from "@/ittijs/components/DateFormat.vue";

export default {
	computed: {
		DeviceTypeLabels() {
			return DeviceTypeLabels
		}
	},
	components: {DateFormat, MyExpand, DeviceEditPopup},
	props: {
		'loading': Boolean,
		'devices': {},
		'imot': {},
		'session': {},
		'locked': Boolean,
		'model': {},
	},
	data(){
		return {
			expand: true,
		}
	},
}
</script>