<template>
	<data-loader :watch="{imot, session}" :callback="loadData" :data.sync="data" ref="loader">
		<template #default>
			<div style="display: contents;">
				<v-btn
					v-for="service in data?.services || []"
					:key="service.id"
					color="warning"
					small
					class="mr-2"
					@click="onServiceClick(service)"
				>
					<v-icon left>mdi-tools</v-icon>
					{{ service.new__device_N }}
				</v-btn>
				<my-dialog v-if="service" v-model="dialog" max-width="700" title="Обработка на монтажна дейност">

					<v-container v-if="service.notes_operator!='' || service.notes_montajnik!=''">
						<v-row dense>
							<v-col cols="6">
								<v-textarea readonly outlined dense hide-details :value="service.notes_operator" label="Бележки оператор" />
							</v-col>
							<v-col cols="6">
								<v-textarea readonly outlined dense hide-details :value="service.notes_montajnik" label="Бележки монтажник" />
							</v-col>
						</v-row>
					</v-container>

					<dev-form-edit
						v-if="dialog"
						ref="form"
						:model="modelEdit"
						hide-title
						edit-buttons=""
						:right-col-width="400"
						:session="session"
						:imot="imot"
						:insert="true"
						:init="initData"
						@save="onSaved"
					>
					</dev-form-edit>

					<template #actions>
						<v-spacer/>
						<edit-buttons @close="doClose" @save="doSave"></edit-buttons>
					</template>

				</my-dialog>
			</div>
		</template>
	</data-loader>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */
import MyDialog from "@/ittijs/components/MyDialog.vue";
import DataLoader from "@/ittijs/components/DataLoader.vue";
import {ITTIModelBase} from "@/ittijs/ITTIModel";
import EditButtons from "@/ittijs/EditButtons.vue";
import DevFormEdit from "@/ittijs/components/DeviceDataList/DevFormEdit.vue";
import ModelDev from "@/views/devices/Model";
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";

class Model extends ModelDev {
	constructor() {
		super('SyncDeviceMontajEdit');
		this.jobServiceID = null;
	}
	save(params, data) {
		params.job_service_id = this.jobServiceID;
		return super.save(params, data);
	}
}

const model = new ITTIModelBase('SyncDeviceMontaj');

export default {
	components: {
		DevFormEdit,
		EditButtons,
		DataLoader,
		MyDialog,
	},
	props: {
		imot: {},
		session: {},
	},
	data(){
		return {
			data         : null,
			dialog       : false,
			service      : null,
			modelEdit    : ITTIModelProvider.getModel(Model),
		}
	},
	computed: {
		srvFieldMap(){
			return {
				'device_type_code'     : 'new__device_type_code',
				'device_model_id'      : 'new__device_model_id',
				'device_N'             : 'new__device_N',
				'radio_N'              : 'new__radio_N',
				'plomba_N'             : 'new__plomba_N',
				'mount_date'           : 'new__mount_date',
				'mount_value'          : 'new__mount_value',
				'reinstall_date'       : 'new__reinstall_date',
				'wpl_id'               : 'new__wpl_id',
				'room'                 : 'new__room',
				'radiator_description' : 'new__radiator_description',
				'notes'                : 'new__notes',
			}
		},
		initData(){
			if (!this.service) return null;
			const result = {};
			for (const k in this.srvFieldMap) {
				result[k] = this.service[ this.srvFieldMap[k] ];
			}
			return result;
		},
	},
	watch: {
	},
	methods: {
		loadData() {
			return model.fetch('getDataNew', {
				imot: this.imot,
				session: this.session,
			});
		},
		onServiceClick(service){
			this.service = service;
			this.dialog = true;
		},
		copyAll(row) {
			for (const nDev in this.srvFieldMap) {
				const nSrv = this.srvFieldMap[nDev];
				this.$set(row, nDev, this.service[nSrv]);
			}
		},
		doClose(){
			this.dialog = false;
		},
		doSave(){
			this.modelEdit.jobServiceID = this.service?.id ?? null;
			return this.$refs.form?.doSave();
		},
		onSaved(){
			this.doClose();
			this.$refs.loader.loadDebounce();
			this.$emit('reload');
		},
	}
}
</script>