<!-- трансфер на годишните отчети в уредите за цялата станция -->

<template>
	<my-dialog ref="dialog" title="Трансфер на годишни отчети" v-bind="$attrs" v-on="$listeners" width="500">
		<template v-slot:activator="scope">
			<slot name="activator" v-bind="scope"/>
		</template>

		<data-loader
			:watch="{selectedUtility, session, station}"
			:callback="loadStatus"
			:loading.sync="statusLoading"
			:data.sync="statusData"
		>
			<template #loading>
				<v-progress-linear indeterminate class="ma-4" rounded />
			</template>

			<v-list v-if="statusData">
				<v-list-item>
					<v-list-item-content>
						Общо показания
					</v-list-item-content>
					<v-list-item-action class="text-right">
						{{statusData.total_readings}}
					</v-list-item-action>
				</v-list-item>
				<v-list-item>
					<v-list-item-content>
						Непрехвърлени показания
					</v-list-item-content>
					<v-list-item-action class="text-right">
						{{statusData.pending_transfer}}
					</v-list-item-action>
				</v-list-item>
				<v-list-item>
					<v-list-item-content>
						Уреди без отчет
					</v-list-item-content>
					<v-list-item-action class="text-right">
						{{statusData.missing_reading}}
					</v-list-item-action>
				</v-list-item>
			</v-list>

		</data-loader>

		<template #actions>
			<v-spacer/>
			<btn-loading-promise :click="()=>doSubmitAll()" @ready="$emit('ready'); close();">
				<template #default="{click,loading}">
					<v-btn color="primary" @click="click" :loading="loading" :disabled="(statusData?.total_readings ?? 0) === 0">
						Всички
					</v-btn>
				</template>
			</btn-loading-promise>
			<btn-loading-promise :click="()=>doSubmitPending()" @ready="$emit('ready'); close();">
				<template #default="{click,loading}">
					<v-btn color="primary" @click="click" :loading="loading" :disabled="(statusData?.pending_transfer ?? 0) === 0 || statusData?.total_readings === statusData?.pending_transfer">
						Само непрехвърлените
					</v-btn>
				</template>
			</btn-loading-promise>
		</template>

	</my-dialog>
</template>

<script>
import MyDialog from "@/ittijs/components/MyDialog.vue";
import BtnLoadingPromise from "@/ittijs/components/BtnLoadingPromise.vue";
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";
import DataLoader from "@/ittijs/components/DataLoader.vue";
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import GodishniOtcheti from "@/views/sessions/Current/GodishniOtcheti";

const model = ITTIModelProvider.getModel(GodishniOtcheti);

export default {
	components: {DataLoader, BtnLoadingPromise, MyDialog},
	mixins: [SelectedUtilityMixin],
	props: [
		'session',
		'station',
	],
	data() {
		return {
			model,
			statusLoading: false,
			statusData: null,
		}
	},
	methods: {
		loadStatus(){
			return this.model.transferStationStatus(this.selectedUtility, this.session, this.station);
		},
		doSubmitAll(){
			return this.model.transferStationReadingsAll(this.selectedUtility, this.session, this.station);
		},
		doSubmitPending(){
			return this.model.transferStationReadingsPending(this.selectedUtility, this.session, this.station);
		},
		close() {
			this.$refs.dialog.doClose();
		},
	},
}
</script>