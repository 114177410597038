<template>
	<v-dialog v-bind="$attrs" v-on="$listeners" v-model="open" max-width="900">
		<template v-slot:activator="scope">
			<slot name="activator" v-bind="scope"/>
		</template>
		<v-card>
			<edit-column
				max-content-height="calc(90vh - 120px)"
				title="Данни станция"
				:params="{session_id:session.id,station_id:stationId}"
				v-if="open"
				:fields="fields"
				@close="open = false"
				@saveclose="open = false; $emit('reload')"
				:edit-button-options="{showDelete:false,showSave:!readonly}"
				:left-col-width="250"
			>
				<template v-slot:row.t_avg_session="{row,imodel}">
					<v-col>
						<div class="mt-4 mb-2">{{ imodel.fields.t_avg_session.label }}</div>
						<avg-temp-calc
							name="t_avg_session"
							v-model="row.t_avg_session"
							:utility="session.utility_code"
							:start="session.date_start"
							:end="session.date_end"
						/>
					</v-col>
				</template>
				<template v-slot:field.day_degree="{row,err}">
					<div class="d-flex align-center">
						<edit-input name="day_degree" outlined dense label="" :data="row" :errors="err" />
						<v-btn small fab icon @click="row.day_degree = dayDegree(row)"><v-icon>mdi-chevron-left-circle</v-icon></v-btn>
						Изчислено: {{ dayDegree(row) }}
					</div>
				</template>
				<template v-slot:field.vodomer_hot_calculated="{row,err}">
					<div class="d-flex align-center">
						<edit-input name="vodomer_hot_calculated" outlined dense label="" :data="row" :errors="err" />
						<btn-loading-promise :click="()=>recalcVodomerHot(row)">
							<template #default="{click,loading}">
								<v-btn small fab icon @click="click" :loading="loading"><v-icon>mdi-refresh</v-icon></v-btn>
							</template>
						</btn-loading-promise>
					</div>
				</template>
			</edit-column>
		</v-card>
	</v-dialog>
</template>

<script>
import {StationDataValues} from "../stations/ModelData";
import EditColumn from "@/ittijs/EditColumn";
import AvgTempCalc from "@/views/clients_screen/AvgTempCalc";
import EditInput from "@/ittijs/Inputs/EditInput";
import BtnLoadingPromise from "@/ittijs/components/BtnLoadingPromise";

/* eslint-disable @typescript-eslint/camelcase */

const model = new StationDataValues();

export default {
	components: {BtnLoadingPromise, EditInput, AvgTempCalc, EditColumn},
	props: [
		'session',
		'stationId',
		'readonly',
	],
	data(){
		return {
			open: false,
			model: model,
			fields:[
				'mesechen',
				'work_days_hw',
				'work_days_heat',
				'work_days_wo_hot_vodomer',
				'toplomer_obsht_value',
				'toplomer_heat_value',
				'vodomer_hot_value',
				'vodomer_hot_calculated',
				'vodomer_cold_value',
				'studomer_value',
				'temperaturen_coeficient',
				'max_hw_m3_to_mwh',
				't_avg_session',
				'day_degree',
			],
			recalcVodomerHotLoading: false,
		}
	},
	methods: {
		dayDegree(row){
			return (row.work_days_heat * (this.session.t_avg_building - row.t_avg_session)).toFixed(6);
		},
		async recalcVodomerHot(row){
			row.vodomer_hot_calculated = (await model.recalcVodomerHot(
				this.stationId,
				this.session.id,
				row.work_days_wo_hot_vodomer,
				row.vodomer_hot_value
			)).toFixed(3);
		},
	},
	provide: {
		imodel: model,
	}
}
</script>
