<!--
Вариант на KeyValTable, който работи с модел и обект key:value вместо масив
-->

<template>
	<key-val-table v-bind="$attrs" :data="mappedData">
		<template #th="{key}">
			<field-label :name="key" />
		</template>
		<template #td="{key,val}">
			<field-value :name="key" :value="val" />
		</template>
		<template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
			<slot :name="slot" v-bind="scope"/>
		</template>
	</key-val-table>
</template>

<script>
import KeyValTable from "@/ittijs/components/KeyValTable.vue";
import IModelMixin from "@/ittijs/IModelMixin";
import FieldLabel from "@/ittijs/components/FieldLabel.vue";
import FieldValue from "@/ittijs/components/FieldValue.vue";

export default {
	mixins: [IModelMixin],
	components: {FieldValue, FieldLabel, KeyValTable},
	props: {
		data: Object,
	},
	computed: {
		mappedData(){
			return Object.entries(this.data)
				.map(item => ({
					key: item[0],
					val: item[1],
				}));
		},
	},
}
</script>