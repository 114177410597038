<template>
	<my-dialog ref="dialog" title="Поемане на станция" v-bind="$attrs" v-on="$listeners" width="500" @open="onOpen">
		<template v-slot:activator="scope">
			<slot name="activator" v-bind="scope"/>
		</template>

		<v-container>
			<v-row>
				<v-col>
					<v-text-field
						outlined dense
						clearable
						hide-details
						v-model="value"
						label="Име на оператор"
					/>
					<v-btn class="mt-2" color="secondary" small @click="value = me">{{me}}</v-btn>
				</v-col>
			</v-row>
		</v-container>

		<template #actions>
			<v-spacer/>
			<btn-loading-promise :click="()=>doSubmit()" @ready="$emit('ready'); close();">
				<template #default="{click,loading}">
					<v-btn color="primary" @click="click" :loading="loading">
						Потвърди
					</v-btn>
				</template>
			</btn-loading-promise>
		</template>

	</my-dialog>
</template>

<script>
import StationData from "../stations/ModelData";
import MyDialog from "@/ittijs/components/MyDialog";
import BtnLoadingPromise from "@/ittijs/components/BtnLoadingPromise";
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";

const model = ITTIModelProvider.getModel(StationData);

export default {
	components: {BtnLoadingPromise, MyDialog},
	inject: ['getUserProfile'],
	props: [
		'station',
		'session',
		'name',
	],
	data() {
		return {
			model,
			me: '',
			value: '',
		}
	},
	methods: {
		onOpen(){
			const p = this.getUserProfile();
			this.me = p.username;
			if (this.name=='') {
				this.value = p.username;
			}
			else {
				this.value = this.name;
			}
		},
		doSubmit(){
			return this.model.setOperator(this.station, this.session, this.value);
		},
		close(){
			this.$refs.dialog.doClose();
		},
	},
}
</script>