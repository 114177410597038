<template>
	<v-card>
		<v-card-title class="text-subtitle-1 headings py-2">
			Уреди в сесията
			<span v-for="doc in activeDocs" :key="doc.id" class="ml-2 red--text font-weight-bold">
				[<field-value :imodel="docModel" name="document_type" :value="doc.document_type" />]
			</span>
			<v-spacer/>
			<v-switch dense hide-details class="my-0 ml-2" label="Скрий студ. водомери" v-model="reactive.devListHideColdWater" />
			<v-switch dense hide-details class="my-0 ml-2" label="Скрий отопл. уреди" v-model="reactive.devListHideHeating" />
		</v-card-title>
		<v-divider v-if="!loading" />
		<v-progress-linear v-if="loading" indeterminate />
		<v-form ref="form">
			<v-simple-table v-if="devices.length" dense>
				<thead>
				<tr>
					<th></th>
					<th>
						Тип,
						Номер,
						Локация
					</th>
					<th></th>
					<th>Старо</th>
					<th>Ново</th>
					<th v-if="izravnitelna">Старо 2</th>
					<th v-if="izravnitelna">Ново 2</th>
					<th class="px-0" v-if="!locked"></th><!-- za montaj merge -->
					<th class="px-0"></th><!-- za samootchet ikonka -->
					<th class="text-right">Разлика</th>
					<th v-if="!mesechen">Прогнозно</th>
					<th v-if="mesechen || izravnitelna">
						<div v-if="!locked">
							<otchet-type
								:disabled="locked"
								:otchet.sync="massOtchetType"
								:samootchet.sync="massSamootchetType"
								@update:otchet="onChangeMassOtchetType()"
								@update:samootchet="onChangeMassSamootchetType()"
							/>
						</div>
					</th>
					<th class="text-right">Ред.пок.</th>
					<th class="text-right">Консумация</th>
					<th class="text-left">Такса</th>
					<th class="text-left" v-if="izravnitelna">
						<v-tooltip top>
							<template #activator="{attrs,on}">
								<span v-bind="attrs" v-on="on">Неиз.</span>
							</template>
							Констатирано е неизправно функциониране на уред
						</v-tooltip>
					</th>
					<th class="text-left" v-if="izravnitelna">
						<v-tooltip top>
							<template #activator="{attrs,on}">
								<span v-bind="attrs" v-on="on">За виз.</span>
							</template>
							За визуален отчет
						</v-tooltip>
					</th>
					<th class="text-right">Фактор</th>
					<th class="text-right">Мощн.</th>
					<th class="px-0" width="1%"></th>
					<th av-if="!locked" class="px-0">
						<!--            <device-edit-popup insert :session="session.id" :imot="imot" @reload="$emit('reload')" />-->
					</th>
				</tr>
				</thead>
				<tbody>
				<tr v-for="device in devicesFiltered" :key="device.device_data.id" :class="classDeviceRow(device)">
					<td>
						<device-type-icon :type="device.device_data.device_type_code" />
					</td>
					<td>
						<v-icon color="green" v-if="device.model && device.model.is_radio==='1'" style="vertical-align: bottom;" title="Радио">mdi-wifi</v-icon>
						{{ device.device_data.device_N }}<br>
						<span class="grey--text">{{ DeviceTypeLabels.get(device.device_data.device_type_code) }} - {{ device.device.room }}</span>
					</td>
					<td><up-device-alert-wrap
						:up-list="upList"
						:device-id="parseInt(device.device.id)"
						@reload="reload"
					/></td>
					<td>
						<template v-if="canEditValueOld(device)">
							<decimal-input
								dense hide-details="auto"
								class="my-1"
								style="width: 80px"
								v-model="values[device.device_data.id].value_old"
								:skip-init-focus="true"
								:disabled="locked || !canEditValueOld(device)"
								keygroup="valueOld"
								@keydown.exact.prevent.up="focusPrev"
								@keydown.exact.prevent.down="focusNext"
								@keydown.exact.prevent.33="onPgUp"
								@keydown.exact.prevent.34="onPgDn"
								:error="error[device.device_data.id]"
								:error-messages="errorMessages[device.device_data.id]"
							/>
						</template>
						<template v-else>
							{{ device.device_data.value_old }}
						</template>
					</td>
					<td>
						<template v-if="canEditValueNew(device)">
							<decimal-input
								dense hide-details="auto"
								class="my-1"
								style="width: 80px"
								v-model="values[device.device_data.id].value_new"
								:disabled="locked || !canEditValueNew(device)"
								keygroup="valueNew"
								@keydown.exact.prevent.up="focusPrev"
								@keydown.exact.prevent.down="focusNext"
								@keydown.exact.prevent.33="onPgUp"
								@keydown.exact.prevent.34="onPgDn"
								:error="error[device.device_data.id]"
								:error-messages="errorMessages[device.device_data.id]"
							/>
						</template>
						<template v-else>
							{{ device.device_data.value_new }}
						</template>
					</td>
					<td v-if="izravnitelna">
						<template v-if="canEditValueOld2(device)">
							<decimal-input
								dense hide-details="auto"
								class="my-1"
								style="width: 80px"
								v-model="values[device.device_data.id].value_old2"
								:disabled="locked || !canEditValueOld2(device)"
								keygroup="valueOld2"
								@keydown.exact.prevent.up="focusPrev"
								@keydown.exact.prevent.down="focusNext"
								@keydown.exact.prevent.33="onPgUp"
								@keydown.exact.prevent.34="onPgDn"
								:error="error[device.device_data.id]"
								:error-messages="errorMessages[device.device_data.id]"
							/>
						</template>
						<template v-else>
							{{ device.device_data.value_old2 }}
						</template>
					</td>
					<td v-if="izravnitelna">
						<template v-if="canEditValueNew2(device)">
							<decimal-input
								dense hide-details="auto"
								class="my-1"
								style="width: 80px"
								v-model="values[device.device_data.id].value_new2"
								:disabled="locked || !canEditValueNew2(device)"
								keygroup="valueNew2"
								@keydown.exact.prevent.up="focusPrev"
								@keydown.exact.prevent.down="focusNext"
								@keydown.exact.prevent.33="onPgUp"
								@keydown.exact.prevent.34="onPgDn"
								:error="error[device.device_data.id]"
								:error-messages="errorMessages[device.device_data.id]"
							/>
						</template>
						<template v-else>
							{{ device.device_data.value_new2 }}
						</template>
					</td>
					<td width="0.001%" class="px-0" v-if="!locked">
						<sync-device-montaj
							:device="device.device_data.device_id"
							:session="device.device_data.session_id"
							@reload="reload"
						/>
					</td>
					<td width="0.001%" class="px-0">
						<self-report-select
							:device="device.device_data.device_id"
							:session="device.device_data.session_id"
							@select="onSelfReportSelect(device.device_data.id, $event)"
							:decimals="parseInt(device.decimals)"
						/>
					</td>
					<td class="text-right">
						<val-diff
							:old="values[device.device_data.id].value_old"
							:new="values[device.device_data.id].value_new"
							:old2="izravnitelna ? values[device.device_data.id].value_old2 : 0"
							:new2="izravnitelna ? values[device.device_data.id].value_new2 : 0"
							class-neg="font-weight-bold deep-orange--text"
							:decimals="parseInt(device.decimals)"
						/>
					</td>
					<td v-if="!mesechen">
						<template v-if="canEditPrognozno(device)">
							<decimal-input
								dense hide-details="auto"
								class="my-1"
								style="width: 100px"
								v-model="values[device.device_data.id].value_prognozno"
								:disabled="locked || !canEditPrognozno(device)"
								keygroup="valueProg"
								@keydown.exact.prevent.up="focusPrev"
								@keydown.exact.prevent.down="focusNext"
								@keydown.exact.prevent.33="onPgUp"
								@keydown.exact.prevent.34="onPgDn"
								:error="error[device.device_data.id]"
								:error-messages="errorMessages[device.device_data.id]"
							/>
						</template>
						<template v-else>
							{{ device.device_data.value_prognozno }}
						</template>
					</td>
					<td v-if="mesechen || izravnitelna">
						<otchet-type
							:disabled="locked || !isAvailable(device)"
							:otchet.sync="values[device.device_data.id].otchet_type"
							:samootchet.sync="values[device.device_data.id].samootchet_type"
						/>
					</td>
					<td class="text-right">{{ redux(device) }}</td>
					<td class="text-right">{{ parseFloat(device.device_data.consumption).toFixed(6) }}</td>
					<td class="text-left">
						<switch-async dense hide-details class="ma-0"
							:skip-init-focus="true"
							v-if="isTaksaEnabled(device)"
							:value="parseInt(device.device_data.taksa_flag) > 0 || false"
							:disabled="locked"
							:callback="state => updateTaksaFlag(device.device_data.id, state)"/>
					</td>
					<td class="text-left" v-if="izravnitelna">
						<switch-async dense hide-details class="ma-0"
							ref="flagNeizpraven"
							:skip-init-focus="true"
							:value="parseInt(device.device_data.neizpraven_flag) > 0 || false"
							:disabled="locked"
							:callback="state => updateNeizpravenFlag(device.device_data.id, state)"/>
					</td>
					<td class="text-left" v-if="izravnitelna">
						<switch-async dense hide-details class="ma-0"
							ref="flagZaVizOtchet"
							:skip-init-focus="true"
							:value="parseInt(device.device_data.za_viz_otchet) > 0 || false"
							:disabled="locked"
							:callback="state => updateZaVizOtchet(device.device_data.id, state)"/>
					</td>
					<td class="text-right">{{ device.is_radiator ? device.device_data.radiator_factor : '' }}</td>
					<td class="text-right">{{ device.is_radiator ? device.device_data.radiator_power : '' }}</td>
					<td class="px-0">
						<device-info-popup :device="{
							d: device.device,
							dd: device.device_data,
							m: device.model || {},
						}" />
					</td>
					<td class="px-0" v-if="!locked">
						<v-menu close-on-click close-on-content-click offset-y>
							<template v-slot:activator="{ on, attrs }">
								<v-btn icon small v-bind="attrs" v-on="on" tabindex="-1">
									<v-icon dense>mdi-dots-vertical</v-icon>
								</v-btn>
							</template>
							<v-list dense>

								<device-edit-popup
									:device="device.device_data.device_id"
									:session="device.device_data.session_id"
									@reload="reload"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-list-item link v-on="on" v-bind="attrs">
											<v-list-item-icon><v-icon color="primary">mdi-pencil</v-icon></v-list-item-icon>
											<v-list-item-title>Редакция</v-list-item-title>
										</v-list-item>
									</template>
								</device-edit-popup>

								<device-replace-popup
									title="Замяна на уред"
									insert
									:device="device.device"
									:session="data.session.id"
									:imot="data.imot.id"
									@reload="reload"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-list-item link v-on="on" v-bind="attrs">
											<v-list-item-icon><v-icon>mdi-swap-horizontal</v-icon></v-list-item-icon>
											<v-list-item-title>Замяна на уред</v-list-item-title>
										</v-list-item>
									</template>
								</device-replace-popup>

								<device-edit-popup
									title="Уред - копирай като нов"
									insert
									:copy="device.device"
									:session="data.session.id"
									:imot="data.imot.id"
									@reload="reload"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-list-item link v-on="on" v-bind="attrs">
											<v-list-item-icon><v-icon color="green">mdi-pencil-plus</v-icon></v-list-item-icon>
											<v-list-item-title>Копирай като нов</v-list-item-title>
										</v-list-item>
									</template>
								</device-edit-popup>

								<confirm-dialog
									label-confirm="Изтрий"
									color-confirm="red"
									icon-confirm="mdi-delete"
									@confirm="removeDeviceData(device.device_data.id)"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-list-item link v-on="on" v-bind="attrs">
											<v-list-item-icon><v-icon color="red">mdi-delete</v-icon></v-list-item-icon>
											<v-list-item-title>Изтрий от сесията</v-list-item-title>
										</v-list-item>
									</template>
									Този уред ще бъде премахнат от текущата сесия.
									Сигурни ли сте?
								</confirm-dialog>

							</v-list>
						</v-menu>
					</td>
				</tr>
				</tbody>
			</v-simple-table>
		</v-form>
		<v-divider v-if="devices.length && !locked" />
		<v-card-actions>
			<slot name="actions-left"/>
			<sync-device-montaj-new
				v-if="!locked"
				:imot="imot"
				:session="session"
				@reload="reload"
			/>
			<v-spacer />

			<btn-loading-promise v-if="showSetProcessedMontaji" :click="setProcessedMontaji" @ready="reload">
				<template #default="{click,loading}">
					<v-btn @click="click" :loading="loading" :disabled="!enabledSetProcessedMontaji"
						small :color="$vuetify.theme.dark ? 'grey darken-2' : 'grey lighten-2'">Обраб. Монтажи</v-btn>
				</template>
			</btn-loading-promise>

			<btn-loading-promise v-if="showSetProcessedOtcheti" :click="setProcessedOtcheti" @ready="reload">
				<template #default="{click,loading}">
					<v-btn @click="click" :loading="loading" :disabled="!enabledSetProcessedOtcheti"
						small :color="$vuetify.theme.dark ? 'grey darken-2' : 'grey lighten-2'">Обраб. Отчети</v-btn>
				</template>
			</btn-loading-promise>

			<v-btn
				v-if="devicesFiltered.length && !locked"
				color="primary"
				:loading="loading"
				:disabled="loading"
				@click="saveData"
				ref="btnSave"
				@keydown.exact.prevent.up="focusPrev"
				small
			>Запиши показанията</v-btn>
			<v-btn
				v-else
				color="primary"
				:loading="loading"
				:disabled="loading"
				@click="$emit('saved')"
				ref="btnContinue"
				@keydown.exact.prevent.up="focusPrev"
				small
			>Продължи</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */

import {RolesEnum} from "@/ittijs/Authorization";
import {ITTIModelBase} from "@/ittijs/ITTIModel";
import DecimalInput from "@/ittijs/Inputs/DecimalInput";
import ConfirmDialog from "@/ittijs/ConfirmDialog";
import FieldValue from "@/ittijs/components/FieldValue";
import OtchetType from "@/ittijs/components/OtchetType";
import ValDiff from "@/ittijs/components/ValDiff";
import SelfReportSelect from "@/ittijs/components/SelfReportSelect";
import DeviceInfoPopup from "./DeviceInfoPopup";
import DeviceEditPopup from "./DeviceEditPopup";
import {ImotDocumentsModel} from "@/views/documents/Model";
import {valueDiff} from "@/ittijs/utils";
import DeviceReplacePopup
	from "@/ittijs/components/DeviceDataList/DeviceReplacePopup";
import {DeviceType, DeviceTypeLabels} from "@/ittijs/Nomenclatures";
import SwitchAsync from "@/ittijs/Inputs/SwitchAsync";
import DeviceTypeIcon
	from "@/ittijs/components/DeviceDataList/DeviceTypeIcon.vue";
import UpDeviceAlertWrap
	from "@/ittijs/components/UvedomitelniPisma/UpDeviceAlertWrap.vue";
import SyncDeviceMontaj from "@/ittijs/components/DeviceDataList/SyncDeviceMontaj.vue";
import SyncDeviceMontajNew from "@/ittijs/components/DeviceDataList/SyncDeviceMontajNew.vue";
import BtnLoadingPromise from "@/ittijs/components/BtnLoadingPromise.vue";

class Model extends ITTIModelBase {
	constructor() {
		super('DeviceDataList');
	}
	getData(session, imot, station){
		return this.fetch('getData', {session, imot, station});
	}
	saveData(session, imot, station, values){
		return this.fetch('saveData', {session, imot, station}, values);
	}
	removeDeviceFromSession(deviceDataId){
		return this.fetch('removeDeviceFromSession', {deviceDataId}, {});
	}
	updateTaksaFlag(deviceDataId, value){
		return this.fetch('updateTaksaFlag', {deviceDataId, value}, {});
	}
	updateNeizpravenFlag(deviceDataId, value){
		return this.fetch('updateNeizpravenFlag', {deviceDataId, value}, {});
	}
	updateZaVizOtchet(deviceDataId, value){
		return this.fetch('updateZaVizOtchet', {deviceDataId, value}, {});
	}
	setProcessedMontaji(imot) {
		return this.fetch('setProcessedMontaji', {imot}, {});
	}
	setProcessedOtcheti(imot) {
		return this.fetch('setProcessedOtcheti', {imot}, {});
	}
}

const model = new Model;
const docModel = new ImotDocumentsModel;

/**
 * fnInputFilter(keygroup) returns a callback function for Array.filter().
 * The filter callback receives parameter el and returns true if the element:
 * - is not disabled or readonly
 * - does not have attribute skip-init-focus
 * - matches keygroup if given (if not given, default to true)
 */
const fnInputFilter = keygroup => el =>
	!(el.disabled || el.readonly || el.$attrs['skip-init-focus'])
	&& ((!keygroup) || keygroup == el.$attrs['keygroup'])
;

export default {
	components: {
		BtnLoadingPromise,
		SyncDeviceMontajNew,
		SyncDeviceMontaj,
		UpDeviceAlertWrap,
		DeviceTypeIcon,
		SwitchAsync,
		DeviceReplacePopup,
		SelfReportSelect,
		ConfirmDialog,
		DeviceEditPopup,
		DeviceInfoPopup,
		ValDiff,
		OtchetType,
		FieldValue,
		DecimalInput,
	},
	inject: [
		'reactive',
		'authorization',
	],
	props: {
		imot: {},
		session: {},
		station: {},
	},
	data: ()=>({
		loading: false,
		data: null,
		values: [],
		docModel,
		massOtchetType: null,
		massSamootchetType: null,
		error: {},
		errorMessages: {},
		focusAfterLoad: false,
		DeviceTypeLabels,
	}),
	watch: {
		imot: {
			immediate: true,
			handler(){
				this.getData();
			},
		},
		session: {
			immediate: true,
			handler(){
				this.getData();
			},
		},
	},
	computed: {
		activeDocs(){
			return this.data && this.data.documents || [];
		},
		devices(){
			return this.data && this.data.devices || [];
		},
		upList(){
			return this.data && this.data.up_list || [];
		},
		izravnitelna(){
			return this.data && this.data.session.izr_sess != '0';
		},
		mesechen(){
			return this.data && this.data.station_data.mesechen === '1';
		},
		locked(){
			return this.data && this.data.session.locked === '1';
		},
		devicesFiltered(){
			return this.data && this.data.devices.filter(d=>this.showDevice(d)) || [];
		},
		isBroiLica(){
			const brl = parseFloat(this.data.imot_data.hot_water_na_brl);
			return this.data && !isNaN(brl) && brl > 0;
		},
		showHeatingDevices(){
			return !this.reactive.devListHideHeating;
		},
		showColdWaterDevices(){
			return !this.reactive.devListHideColdWater;
		},
		advanceButton(){
			if (this.devicesFiltered.length && !this.locked) {
				return this.$refs.btnSave;
			}
			else {
				return this.$refs.btnContinue;
			}
		},
		showSetProcessedMontaji() {
			return !this.locked;
		},
		showSetProcessedOtcheti() {
			return !this.locked;
		},
		enabledSetProcessedMontaji() {
			// false if already processed
			return !(this.data?.is_processed_montaji ?? true);
		},
		enabledSetProcessedOtcheti() {
			// false if already processed
			return !(this.data?.is_processed_otcheti ?? true);
		},
	},
	methods: {
		async getData() {
			if (this.loading) return;
			this.loading = true;
			try {
				this.data = null;
				this.values = {};
				this.data = await model.getData(this.session, this.imot, this.station);
				this.values = Object.fromEntries(
					this.data.devices.map(d => [
						d.device_data.id, // key in this.values
						{
							id              : d.device_data.device_id,
							otchet_type     : d.device_data.otchet_type,
							samootchet_type : d.device_data.samootchet_type,
							value_old       : d.device_data.value_old,
							value_new       : d.device_data.value_new,
							value_old2      : d.device_data.value_old2,
							value_new2      : d.device_data.value_new2,
							value_prognozno : d.device_data.value_prognozno,
						}
					])
				);
			}
			finally {
				this.loading = false;
				if (this.focusAfterLoad) {
					this.focusAfterLoad = false;
					this.$nextTick(()=>{
						this.focusFirst();
					});
				}
			}
		},
		async saveData() {
			if (this.loading) return;
			this.loading = true;
			try {
				// filter only values for visible devices
				const vals = Object.fromEntries(Object.entries(this.values).filter(
					entry => this.devicesFiltered.some(
						dev => parseInt(dev.device_data.id) === parseInt(entry[0])
					)
				));

				this.data = await model.saveData(this.session, this.imot, this.station, vals);
				this.values = {};

				this.values = Object.fromEntries(
					this.data.devices.filter(d=>this.isAvailable(d)).map(d => [
						d.device_data.id, // key in this.values
						{
							id              : d.device_data.device_id,
							otchet_type     : d.device_data.otchet_type,
							samootchet_type : d.device_data.samootchet_type,
							value_old       : d.device_data.value_old,
							value_new       : d.device_data.value_new,
							value_old2      : d.device_data.value_old2,
							value_new2      : d.device_data.value_new2,
							value_prognozno : d.device_data.value_prognozno,
						}
					])
				);
				this.$emit('saved');
			}
			finally {
				this.loading = false;
			}
		},
		redux(device){
			const diff = this.izravnitelna
				? valueDiff(
					device.device_data.value_old,
					device.device_data.value_new,
					device.device_data.value_old2,
					device.device_data.value_new2
				)
				: valueDiff(
					device.device_data.value_old,
					device.device_data.value_new,
					0,
					0
				)
			;
			const val = diff * parseFloat(device.device_data.radiator_factor);
			if (isNaN(val)) return '';
			return val.toFixed(2);
		},
		showDevice(device){
			if (!this.showHeatingDevices) {
				switch (device.device_data.device_type_code) {
					case 'RAZPREDELITEL':
					case 'TOPLOMER':
					case 'SHTRANGLIRA':
					case 'ETALON':
					case 'ETALON_SLAVE':
					case 'RADIATOR_BEZ_URED':
						return false;
				}
			}
			if (!this.showColdWaterDevices) {
				switch (device.device_data.device_type_code) {
					case 'VODOMER_COLD':
						return false;
				}
			}
			return true;
		},
		isAvailable(device){
			return this.mesechen ? this.isAvailableMesechen(device) : this.isAvailableGodishen(device);
		},
		isTaksaEnabled(device){
			switch (device.device_data.device_type_code) {
				case 'RAZPREDELITEL':
				case 'TOPLOMER':
				case 'VODOMER_HOT':
				case 'VODOMER_COLD':
				case 'STUDOMER':
					return true;
				default:
					return false;
			}
		},
		isAvailableMesechen(device){
			// etalon slave is always disabled
			if (device.device_data.device_type_code==='ETALON_SLAVE') return false;

			if (this.isBroiLica && !this.izravnitelna && device.device_data.device_type_code==='VODOMER_HOT') return false;
			if (this.isBroiLica && !this.izravnitelna && device.device_data.device_type_code==='VODOMER_COLD') return false;

			// toplomerite ne se vavejdat izvan otoplitelnia sezon
			// if (!this.isToplo) {
			// 	switch (device.dd.device_type_code) {
			// 		case "RAZPREDELITEL":
			// 		case "TOPLOMER":
			// 		case "SHTRANGLIRA":
			// 		case "ETALON":
			// 		case "RADIATOR_BEZ_URED":
			// 			return false;
			// 	}
			// }

			return true;
		},
		isAvailableGodishen(device){
			if (this.isBroiLica && !this.izravnitelna && device.device_data.device_type_code==='VODOMER_HOT') return false;
			if (this.isBroiLica && !this.izravnitelna && device.device_data.device_type_code==='VODOMER_COLD') return false;

			return true;
		},
		validate(){
			if (!this.$refs.form.validate()) {
				return;
			}
			this.error = {};
			this.errorMessages = {};
			return Object.keys(this.error).length===0;
		},
		onChangeMassOtchetType(){
			if (this.massOtchetType === null) return;
			for (const d of this.devicesFiltered) {
				if (!this.isAvailable(d)) continue;
				this.values[d.device_data.id].otchet_type = this.massOtchetType;
			}
			this.$nextTick(()=>{
				this.massOtchetType = null;
			});
		},
		onChangeMassSamootchetType(){
			if (this.massSamootchetType===null) return;
			for (const d of this.devicesFiltered) {
				if (!this.isAvailable(d)) continue;
				this.values[d.device_data.id].samootchet_type = this.massSamootchetType;
			}
			this.$nextTick(()=>{
				this.massSamootchetType = null;
			});
		},
		canEditValueOld(device){
			return this.canEditValueNew(device) && this.authorization.hasRole(RolesEnum.OTCHETI_FULL);
		},
		canEditValueOld2(device){
			return this.canEditValueNew2(device) && this.authorization.hasRole(RolesEnum.OTCHETI_FULL);
		},
		canEditValueNew(device){
			// редовна сесия на годишна станция - редактира се прогнозата
			if (!this.mesechen && !this.izravnitelna) return false;
			return this.isAvailableMesechen(device);
		},
		canEditValueNew2(device){
			// редовна сесия на годишна станция - редактира се прогнозата
			if (!this.mesechen && !this.izravnitelna) return false;
			return this.isAvailableMesechen(device);
		},
		canEditPrognozno(device){
			if (this.mesechen || this.izravnitelna) return false;
			return this.isAvailableGodishen(device);
		},
		onPgUp(){
			this.$emit('pageup');
		},
		onPgDn(){
			this.$emit('pagedown');
		},
		/**
		 * Focuses the first input that is not an "old value"
		 */
		focusFirst(){
			if (this.loading) {
				this.focusAfterLoad = true;
				return;
			}
			const i = this.$refs.form.inputs.filter(fnInputFilter(null));
			if (!i.length) {
				this.advanceButton.$el.focus();
				return;
			}
			for (const item of i) {
				item.focus();
				item.$refs.input.select();
				break;
			}
		},
		_focusTheOneBefore(list, element){
			let last = null;
			for (const e of list) {
				if (e.$refs.input === element || e.$el === element) {
					if (!last) break;
					if (last.$options.name==='v-btn') {
						last.$el.focus();
					}
					else {
						last.focus();
						last.$refs.input.select();
					}
					return;
				}
				last = e;
			}
		},
		focusPrev(event){
			// go DOWN the list of devices and focus on the one ABOVE the current one
			const keygroup = event.target.getAttribute('keygroup');
			// valueNew and valueNew2 are chained together
			const list = [
				...( keygroup === 'valueNew2' ? this.$refs.form.inputs.filter(fnInputFilter('valueNew')) : [] ),
				...this.$refs.form.inputs.filter(fnInputFilter(keygroup)),
				...( keygroup === 'valueNew' ? this.$refs.form.inputs.filter(fnInputFilter('valueNew2')) : [] ),
				this.advanceButton,
			];
			this._focusTheOneBefore(list, event.target);
		},
		focusNext(event){
			// go UP the list of devices and focus on the one BELOW the current one
			const keygroup = event.target.getAttribute('keygroup');
			// valueNew and valueNew2 are chained together
			const list = [
				...( keygroup === 'valueNew2' ? this.$refs.form.inputs.filter(fnInputFilter('valueNew')) : [] ),
				...this.$refs.form.inputs.filter(fnInputFilter(keygroup)),
				...( keygroup === 'valueNew' ? this.$refs.form.inputs.filter(fnInputFilter('valueNew2')) : [] ),
				this.advanceButton,
			].reverse();
			this._focusTheOneBefore(list, event.target);
		},
		onSelfReportSelect(ddid, value){
			const d = this.devicesFiltered.find(item => ddid === item.device_data.id);
			if (!d) return;
			if (!this.canEditValueNew(d)) return;
			const mem = parseFloat(value.value_mem);
			if (!isNaN(mem) && this.izravnitelna && this.canEditValueNew2(d)) {
				this.values[ddid].value_new2 = value.value;
				this.values[ddid].value_old2 = 0;
				this.values[ddid].value_new = value.value_mem;
			}
			else {
				this.values[ddid].value_new = value.value;
			}
			this.values[ddid].otchet_type = value.otchet_type;
			this.values[ddid].samootchet_type = value.samootchet_type;
		},
		async removeDeviceData(ddId) {
			await model.removeDeviceFromSession(ddId);
			await this.reload();
		},
		async reload(){
			await this.getData();
			this.$emit('reload');
		},
		isDeviceHighlight(device){
			switch (device.device_data.device_type_code) {
				case DeviceType.VODOMER_COLD:
				case DeviceType.VODOMER_HOT:
					return true;
			}
			return false;
		},
		isDeviceHighlightBottom(device){
			switch (device.device_data.device_type_code) {
				case DeviceType.SHTRANGLIRA:
				case DeviceType.ETALON_SLAVE:
				case DeviceType.RADIATOR_BEZ_URED:
					return true;
			}
			return false;
		},
		classDeviceRow(device){
			return {
				"orange lighten-5"   : !this.$vuetify.theme.dark && this.isDeviceHighlight(device),
				"cyan darken-4"      : this.$vuetify.theme.dark && this.isDeviceHighlight(device),
				"purple lighten-5"   : !this.$vuetify.theme.dark && this.isDeviceHighlightBottom(device),
				"purple darken-3"    : this.$vuetify.theme.dark && this.isDeviceHighlightBottom(device),
			};
		},
		updateTaksaFlag(device_data_id, value){
			return model.updateTaksaFlag(device_data_id, value);
		},
		updateNeizpravenFlag(device_data_id, value){
			return model.updateNeizpravenFlag(device_data_id, value);
		},
		updateZaVizOtchet(device_data_id, value){
			return model.updateZaVizOtchet(device_data_id, value);
		},
		setProcessedMontaji() {
			return model.setProcessedMontaji(this.imot);
		},
		setProcessedOtcheti() {
			return model.setProcessedOtcheti(this.imot);
		},
	},
}
</script>