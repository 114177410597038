<template>
	<div :style="style" class="grey lighten-1 d-flex justify-center align-center">
		<iframe
			v-if="showMap"
			frameborder="0"
			scrolling="no"
			marginheight="0"
			marginwidth="0"
			:src="frameSrc"
			style="border: 1px solid black; width: 100%; height: 100%;"
		></iframe>
		<v-icon v-else size="120" color="grey">mdi-map-marker-off-outline</v-icon>
	</div>
</template>

<script>

import {debounce} from "lodash";

const validCoord = function(value, min, max) {
	return typeof value === 'number'
		&& !isNaN(value)
		&& value >= min
		&& value <= max
}

export default {
	props: {
		lat: Number,
		lng: Number,
		width: {
			type: Number,
			default: 600,
		},
		height: {
			type: Number,
			default: 450,
		},
	},
	computed: {
		showMap(){
			return validCoord(this.lat, -90, 90)
				&& validCoord(this.lng, -180, 180)
		},
		style(){
			return `width: ${this.width}px; height: ${this.height}px;`;
		},
	},
	data(){
		return {
			frameSrc: '',
			updateFrameSrc: debounce(()=>{
				if (this.showMap) {
					this.frameSrc = `https://maps.google.com/maps?output=embed&ll=${this.lat},${this.lng}&z=18&q=${this.lat},${this.lng}`;
				}
				else {
					this.frameSrc = '';
				}
			}, 100)
		}
	},
	watch: {
		lat: {
			immediate: true,
			handler(){
				this.updateFrameSrc();
			}
		},
		lng: {
			immediate: true,
			handler(){
				this.updateFrameSrc();
			}
		},
		showMap: {
			immediate: true,
			handler(){
				this.updateFrameSrc();
			}
		},
	}
}
</script>