<template>
	<my-dialog v-if="items && items.length" :max-width="800" title="Еталонни уреди">
		<template #activator="{attrs,on}">
			<v-btn v-bind="attrs" v-on="on" small>Еталонни</v-btn>
		</template>
		<v-data-table
			:items="items"
			:headers="headers"
			sort-by="address"
			disable-pagination
			hide-default-footer
		/>
	</my-dialog>
</template>

<script>
import MyDialog from "@/ittijs/components/MyDialog";
import IModelMixin from "@/ittijs/IModelMixin";
export default {
	mixins: [
		IModelMixin,
	],
	components: {MyDialog},
	props: {
		items: Array,
	},
	computed: {
		headers: ()=>([
			{value: 'device_N', text: 'Уред номер'},
			{value: 'imot_N',   text: 'Имот номер'},
			{value: 'address',  text: 'Адрес'},
			{value: 'room',     text: 'Стая'},
		]),
	},
}
</script>