var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"3"}},[_c('table-data-view-model',{staticClass:"mb-2",attrs:{"fields":[
					// Administrativni danni a kontakt na AS
					'station_N',
					_vm.smodel.fields.building_N,
					'mesechen',
					'domoupravitel_name',
					'domoupravitel_phone',
					'domoupravitel_email',
					'authorized_persons',
				],"title":_vm.data.address,"data":{
					..._vm.data,
					building_N: _vm.station.building_N,
				}},scopedSlots:_vm._u([(_vm.station.notes)?{key:"after-rows",fn:function(){return [_c('tr',[_c('td',{staticClass:"py-2",attrs:{"colspan":"2"}},[_c('strong',[_vm._v("Забележка:")]),_c('div',{staticStyle:{"white-space":"pre-wrap"}},[_vm._v(_vm._s(_vm.station.notes))])])])]},proxy:true}:null],null,true)}),_c('table-data-view-model',{staticClass:"mb-2",attrs:{"fields":[
					// Parvichni danni parametri na AS
					'VID_SGR',
					_vm.smodel.fields.install_power_heat,
					'sgri_2_zones',
					'sgri_method',
					'sgri_percent',
					'sgri_percent_2',
					'sgri_moshtnost',
					'water_type',
					'status',
					//'otchetnik_id',
				],"title":"Първични данни параметри на АС","data":{
					..._vm.data,
					install_power_heat: _vm.station.install_power_heat,
				}}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('table-data-view-model',{staticClass:"mb-2",attrs:{"fields":[
					// Parvichni danni ot Toplofikacia
					'work_days_hw',
					'work_days_heat',
					'work_days_wo_hot_vodomer',
					't_avg_session',
					'day_degree',
					'temperaturen_coeficient',
					'toplomer_obsht_value',
					'toplomer_heat_value',
					'vodomer_hot_value',
					'vodomer_hot_calculated',
					'vodomer_cold_value',
					'studomer_value',
					'max_hw_m3_to_mwh',
				],"title":"Първични данни от дружеството","data":_vm.data}}),_c('table-data-view-model',{staticClass:"mb-2",attrs:{"fields":[
					// Izchisleni danni na AS
					'sum_volume',
					'sum_radiator_power',
					'sum_reded',

					'k_bgv_kor_m3',
					'k_bgv_kor_brl',
					'hw_m3_to_mwh_summer',
					'base_hw_power',

					'msrs',
					'corekcionen_coeficient',
					'reded_to_mwh',
					'e_hot_water',
					'e_hot_water_over',
					'e_heat_and_sgri',
					'e_sgri',
					'e_sgri_dobavena',
					'e_uredi_razpr',
					'e_uredi_toplomer',
					'sgri_percent_izr',
					'voda_za_razpr_m3',

				],"title":"Изчислени данни на АС","data":_vm.data}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{staticClass:"mb-2",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Бележки")]),_c('v-card-text',[_c('station-notes',{attrs:{"station":_vm.data.station_id}})],1)],1),_c('v-card',{staticClass:"mb-2",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Документи")]),_c('v-card-text',[_c('document-list',{attrs:{"station":_vm.data.station_id,"documents":_vm.documents},on:{"update:documents":function($event){_vm.documents=$event}}})],1)],1),_c('v-card',{staticClass:"mb-2",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Деловодство")]),_c('v-card-text',[_c('tickets',{attrs:{"station":_vm.data.station_id,"no-new-record":""}})],1)],1),(_vm.data.mesechen!=='1')?_c('table-data-view-model',{staticClass:"mb-2",attrs:{"fields":[
				'izr_hw_m3_to_mwh',
				'izr_k_bgv_kor_m3',
				'izr_k_bgv_kor_brl',
				'izr_rd_bgv',
			],"title":"Данни от изравнителна","data":_vm.data}}):_vm._e(),(_vm.data.qMax_devices)?_c('v-card',{staticClass:"mb-2",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("qMax_devices")]),_c('v-card-text',[_c('pre',{staticStyle:{"max-height":"400px","overflow":"auto"}},[_vm._v(_vm._s(_vm.data.qMax_devices))])])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }