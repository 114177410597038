<template>
	<v-card v-bind="$attrs">
		<v-card-title class="text-subtitle-1 headings py-2 pl-2" style="cursor: pointer;" @click="state=!state">
			<v-icon small class="mr-1">{{ state ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
			<slot name="title">{{ title }}</slot>
			<v-spacer/>
			<slot name="title-right"/>
		</v-card-title>
		<v-divider v-if="!loading" />
		<v-progress-linear v-if="loading" indeterminate />
		<v-expand-transition>
			<div v-show="state"><slot/></div>
		</v-expand-transition>
	</v-card>
</template>

<script>
export default {
	name: "MyExpand",
	props: {
		loading: Boolean,
		title: String,
		expand: Boolean, // supports sync
	},
	data: ()=>({
		state: false,
	}),
	watch: {
		expand: {
			handler(val){
				this.state = val;
			},
			immediate: true,
		},
		state(val){
			this.$emit('update:expand', val);
			this.$emit(val ? 'open' : 'close');
		},
	},
}
</script>