<template>
	<edit-column
		ref="form"
		v-bind="$attrs"
		v-on="$listeners"
		:title="title || 'Уред - редакция'"
		:params="insert ? {imot,session} : {id:device,session}"
		:data.sync="data"
		:fields-callback="fields"
		:imodel="model"
		:left-col-width="250"
	>

		<template v-slot:field.device_model_id="{row,err}">
			<device-model
				label=""
				outlined
				dense
				name="device_model_id"
				hide-details="auto"
				:device-type="row.device_type_code"
				:data="row"
				:errors="err.device_model_id"
				:radio.sync="isModelRadio"
			/>
		</template>

		<template v-slot:field.device_N="{row,err,disabled}">
			<edit-input
				label=""
				outlined dense
				name="device_N"
				:data="row"
				:errors="err"
				:disabled="disabled"
			></edit-input>
			<dup-dev-num :number="row.device_N" :id="row.id" />
			<replacement-info :device-id="row.id" />
		</template>

		<template v-slot:field.reinstall_date="{row,err,disabled}">
			<edit-input
				label=""
				outlined dense
				name="reinstall_date"
				:data="row"
				:errors="err"
				:disabled="disabled"
			></edit-input>
			<em class="grey--text">Автоматично: 10 години след монтаж</em>
		</template>

		<template v-slot:field.device_type_code="{row,err,disabled}">
			<device-type-select
				:insert="!(row.id > 0)"
				label=""
				outlined dense
				name="device_type_code"
				:data="row"
				:errors="err"
				:disabled="disabled"
			></device-type-select>
		</template>

		<template v-slot:field.room="{row,err,disabled}">
			<data-loader :watch="roomOptionsParams" :callback="()=>model.getRoomOptions(roomOptionsParams.utility, roomOptionsParams.device_type_code)">
				<template #default="{data}">
					<edit-input
						type="combobox"
						label=""
						outlined dense
						name="room"
						:data="row"
						:errors="err"
						:disabled="disabled"
						:items="data"
					/>
				</template>
			</data-loader>
		</template>

		<template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"><slot :name="slot" v-bind="scope"/></template>

	</edit-column>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */
import EditInput from "@/ittijs/Inputs/EditInput.vue";
import EditColumn from "@/ittijs/EditColumn.vue";
import DeviceTypeSelect from "@/ittijs/components/DeviceTypeSelect.vue";
import DeviceModel from "@/views/device_models/DeviceModelSelect.vue";
import DupDevNum from "@/views/clients_screen/DupDevNum.vue";
import DataLoader from "@/ittijs/components/DataLoader.vue";
import Model from "@/views/devices/Model";
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";
import {deviceInGroup, DeviceTypeGroupTypes} from "@/ittijs/Nomenclatures";
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import ReplacementInfo from "@/ittijs/components/DeviceDataList/ReplacementInfo.vue";

export default {
	components: {ReplacementInfo, DataLoader, DupDevNum, DeviceModel, DeviceTypeSelect, EditColumn, EditInput},
	mixins: [
		SelectedUtilityMixin,
	],
	props: {
		insert      : {},
		imot        : {},
		device      : {},
		session     : {},
		title       : String,
		model       : {
			type: Object,
			default: () => ITTIModelProvider.getModel(Model),
		},
	},
	data(){
		return {
			data: null,
			isModelRadio: false,
		}
	},
	computed: {
		roomOptionsParams(){
			return {
				utility          : this.selectedUtility,
				device_type_code : this.data && this.data.device_type_code || null,
			}
		},
	},
	methods: {
		radioPropsVisible(){
			return this.isModelRadio;
		},
		value2Visible(){
			try {
				return deviceInGroup(this.data.device_type_code, DeviceTypeGroupTypes.Value2);
			}
			catch (e) {
				return false;
			}
		},
		fields(row){
			return [
				'device_type_code',
				'device_model_id',
				'device_N',
				this.radioPropsVisible() ? 'radio_N' : null,
				'plomba_N',
				'mount_date',
				!this.insert ? 'unmount_date': null,
				'mount_value',
				!this.insert ? 'unmount_value': null,
				!this.insert && this.value2Visible() ? 'unmount_value2' : null,
				'reinstall_date',
				'wpl_id',
				'room',
				'radiator_factor',
				'radiator_power',
				'radiator_description',
				'notes',
			].filter(f=>!!f).filter(f=>this.model.getHiddenFieldsByType(row['device_type_code']).indexOf(f)===-1);
		},
		doSave(){
			return this.$refs.form.doSave();
		},
	},
}
</script>