import ITTIModel from '../../ittijs/ITTIModel';

/* eslint-disable @typescript-eslint/camelcase */

export default class StationData extends ITTIModel {
	constructor(modelName) {
		super(modelName || 'StationsData');
	}
	setOperator(station, session, operatorName){
		return this.fetch('setOperator', {station, session, name: operatorName});
	}
}

export class StationDataValues extends StationData {
	constructor() {
		super('StationsDataValues');
	}
	recalcVodomerHot(station, session, work_days_wo_hot_vodomer, vodomer_hot_value){
		return this.fetch('recalcVodomerHot', {station, session, work_days_wo_hot_vodomer, vodomer_hot_value});
	}
}

export class StationDataCalc extends StationData {
	constructor() {
		super('StationsDataCalc');
	}
	calc_sum_radiator_power(station, session){
		return this.fetch('calc_sum_radiator_power', {station, session});
	}
}
