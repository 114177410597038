import ITTIModel from '../../ittijs/ITTIModel';
export default class extends ITTIModel {
	constructor() {
		super('IttiEnum');
		this.cache = {};
	}
	async getValues(code){
		if (!this.cache[code]) {
			this.cache[code] = await this.fetch('getValues', {code});
		}
		return this.cache[code];
	}
}