import {ITTIModelBase} from "@/ittijs/ITTIModel";

export default class GodishniOtcheti extends ITTIModelBase {
	constructor() {
		super('GodishniOtcheti');
	}
	transferStationStatus(utility: string, session: number, station: number){
		return this.fetch('transferStationStatus', {utility, session, station});
	}
	transferStationReadingsAll(utility: string, session: number, station: number){
		return this.fetch('transferStationReadingsAll', {utility, session, station}, {});
	}
	transferStationReadingsPending(utility: string, session: number, station: number){
		return this.fetch('transferStationReadingsPending', {utility, session, station}, {});
	}
	// deleteReadings(utility: string, session: number){
	// 	return this.fetch('deleteReadings', {utility, session}, {});
	// }
}
