<template>
	<my-dialog max-width="900" title="Служебни" @open="onOpen" @close="onClose" ref="dialog">
		<template #activator="scope">
			<slot name="activator" v-bind="scope" />
		</template>

		<div v-if="loading" class="pa-4 text-center">
			<v-progress-circular indeterminate color="grey" />
		</div>

		<v-form ref="form" v-if="!loading">
			<v-container>
				<v-row>
					<v-col>
						<v-simple-table>
							<thead>
							<tr>
								<th>ТИП</th>
								<th>СЕСИЯ</th>
								<th width="1%">КОРЕКЦИЯ</th>
							</tr>
							</thead>
							<tbody>
							<tr v-for="(label,type) of rowsMap" :key="type">
								<td>{{label}}</td>
								<td>
									<session-select
										dense outlined clearable
										:utility="selectedUtility"
										:station="station"
										v-model="data[type].session"
									/>
								</td>
								<td>
									<v-text-field
										dense outlined hide-details="auto" suffix="%"
										style="width: 150px"
										v-model="data[type].correction"
									/>
								</td>
							</tr>
							</tbody>
						</v-simple-table>
					</v-col>
				</v-row>
			</v-container>
		</v-form>

		<v-divider v-if="!loading" />

		<div class="d-flex pa-2" v-if="!loading">
			<v-spacer/>
			<v-btn text @click="close">Затвори</v-btn>
			<btn-loading-promise
				v-if="!readonly"
				:click="()=>update()"
				@ready="$emit('ready'); close();"
			>
				<template #default="{click,loading}">
					<v-btn color="primary" @click="click" :loading="loading" class="ml-2">
						Потвърди
					</v-btn>
				</template>
			</btn-loading-promise>
		</div>

	</my-dialog>
</template>

<script>
import MyDialog from "@/ittijs/components/MyDialog";
import SessionSelect from "@/views/sessions/SessionSelect";
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import BtnLoadingPromise from "@/ittijs/components/BtnLoadingPromise";
import IModelMixin from "@/ittijs/IModelMixin";
import {cloneDeep} from "lodash";

const typeMap = {
	radiator: "Радиатори",
	vodomer: "Водомери",
	toplomer: "Топломери",
};

const dataInit = Object.fromEntries(
	Object.keys(typeMap)
		.map(k => [k, {session: null, correction: null}])
);

export default {
	mixins: [SelectedUtilityMixin, IModelMixin],
	components: {BtnLoadingPromise, SessionSelect, MyDialog},

	props: [
		'session',
		'station',
		'readonly',
	],

	data(){
		return {
			data: cloneDeep(dataInit),
			rowsMap: cloneDeep(typeMap),
			loading: false,
		}
	},

	methods: {
		onOpen(){
			this.load();
		},
		onClose(){
			this.data = cloneDeep(dataInit);
			//this.$refs.form.resetValidation();
		},
		close(){
			this.$refs.dialog.doClose();
		},
		async update(){
			// if (!this.$refs.form.validate()) {
			// 	throw "error";
			// }
			await this.imodel.recalcSlujeben(this.session, this.station, this.data);
		},
		async load(){
			if (this.loading) return;
			this.loading = true;
			this.data = await this.imodel.getConfigSlujeben(this.session, this.station);
			this.loading = false;
		},
	},

}
</script>