<template>
	<div>
		<template v-if="!imodel.loaded"><v-progress-linear indeterminate /></template>
		<template v-else>
			<div v-show="!isEdit">
				<Search @filter="filter = $event" :defaults="defaultSearch" />
				<v-toolbar flat>
					<v-toolbar-title>{{ imodel.Title }}</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-btn @click="onAdd" dark color="primary"><v-icon left>mdi-plus</v-icon> Нов запис</v-btn>
				</v-toolbar>
				<List
					ref="list"
					headers="id,utility_code,station_N,building_N,zip,address,station_type,n_utility_session_template_id"
					:filter="filter2"
					@edit="onEdit"
				/>
			</div>
			<div v-if="isEdit">
				<v-tabs v-model="tabs">
					<v-tab>Обща информация</v-tab>
					<v-tab v-if="id!=='new'">Имоти</v-tab>
					<v-tab v-if="id!=='new'">Бележки</v-tab>
				</v-tabs>
				<v-tabs-items v-model="tabs">
					<v-tab-item eager><!-- eager because the station edit form is needed by the imot edit form -->
						<edit-column
							column
							:id="id"
							:init="{utility_code:selectedUtility}"
							:hidden-fields-callback="getHiddenStationFields"
							@saveclose="onSaveClose"
							@save="onSave"
							@close="onEditCancel"
							ref="EditForm"
						>
							<template #after-row-right.gps_lng="{row}">
								<gmap-iframe
									:lat="parseFloat(row.gps_lat)"
									:lng="parseFloat(row.gps_lng)"
								/>
							</template>
							<template v-slot:after-fields-right="{row}" v-if="id > 0">
								<session-station-add-remove :station-id="parseInt(row.id)" />
							</template>
						</edit-column>
					</v-tab-item>
					<v-tab-item v-if="id!=='new'">
						<v-toolbar flat dense>
							<v-spacer/>
							<my-dialog max-width="1200" title="Импорт на имоти">
								<template #activator="{attrs,on}">
									<v-btn color="primary" text v-bind="attrs" v-on="on">
										<v-icon left>mdi-folder-multiple-plus-outline</v-icon>
										Импорт
									</v-btn>
								</template>
								<template #default="{close}">
									<imot-import :imodel="imodel" :station-id="id" @imported="close(); reloadImotList();" />
								</template>
							</my-dialog>
							<v-btn color="primary" @click="onAddImot" class="ml-2">
								<v-icon left>mdi-plus</v-icon>
								Добави имот
							</v-btn>
						</v-toolbar>
						<v-divider />
						<List
							ref="imotList"
							:imodel="imotModel"
							:params="{station_id:id}"
							disable-pagination
							hide-delete
							hide-select
							headers="imot_N,imot_type,address,location,client_name,active_from_date,active_to_date"
							@edit="onEditImot"
						/>
						<v-dialog v-model="editImot" max-width="900" :transition="false">
							<v-card>
								<edit-column
									v-if="editImot"
									max-content-height="calc(90vh - 120px)"
									:left-col-width="250"
									title="Имот"
									:id="imotId"
									:imodel="imotModel"
									:constrains="{station_id:id}"
									hide-constrained
									:hidden-fields-callback="getHiddenImotFields"
									:edit-button-options="{showDelete:false}"
									@close="editImot = false"
									@saveclose="editImot = false; reloadImotList();"
								>
									<template #after-fields-right="{row}" v-if="imotId > 0">
										<session-imot-add-remove :imot-id="parseInt(row.id)" :station-id="parseInt(id)" />
									</template>
								</edit-column>
							</v-card>
						</v-dialog>
					</v-tab-item>
					<v-tab-item v-if="id!=='new'">
						<div style="width: 700px;" class="mt-2">
							<station-notes :station="id" />
						</div>
					</v-tab-item>
				</v-tabs-items>
			</div>
		</template>
	</div>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */

import Model from "./Model";
import List from "@/ittijs/List";
import Search from "@/ittijs/Search";
import IndexMixin from "@/ittijs/IndexMixin";
import SelectedUtilityMixin from '@/ittijs/SelectedUtilityMixin';
import {cloneDeep, uniq} from "lodash";
import EditColumn from "@/ittijs/EditColumn";
import ImotModel from "../imot/Model";
import StationNotes from "@/views/clients_screen/StationNotes";
import SessionImotAddRemove from "@/views/imot/sessionImotAddRemove";
import SessionStationAddRemove from "@/views/imot/sessionStationAddRemove";
import MyDialog from "@/ittijs/components/MyDialog";
import ImotImport from "@/views/stations/ImotImport";
import Validators from "@/ittijs/Validators";
import {FilterOps} from "@/ittijs/ITTIModel";
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";
import GmapIframe from "@/ittijs/components/GmapIframe.vue";

const imodel = new Model();
const imotModel = ITTIModelProvider.getModel(ImotModel);

export function getHiddenStationFields(row){
	const fields = [];
	if (row.VID_SGR != '0.15') {
		fields.push('sgri_2_zones');
		row.sgri_2_zones = 0;
	}
	switch (row.sgri_method) {
		case '1': fields.push('sgri_percent', 'sgri_percent_2', 'sgri_moshtnost'); break;
		case '2': fields.push('sgri_percent', 'sgri_percent_2'); break;
		case '3': fields.push('sgri_percent', 'sgri_percent_2'); break;
		case '4': fields.push('sgri_moshtnost'); break;
		case '5': fields.push('sgri_percent', 'sgri_percent_2', 'sgri_moshtnost'); break;
	}
	if (row.sgri_2_zones!=='1') {
		fields.push('sgri_percent_2');
	}
	return uniq(fields);
}

export default {

	components: {
		GmapIframe,
		ImotImport,
		MyDialog,
		SessionImotAddRemove,
		SessionStationAddRemove,
		StationNotes,
		EditColumn,
		Search,
		List,
	},

	mixins: [
		IndexMixin,
		SelectedUtilityMixin,
	],

	data(){
		return {
			Validators,
			tabs: null,
			defaultSearch: {
				rules:[
					{
						field: 'station_N',
						op: FilterOps.EQUALS,
						value: '',
					},
					{
						field: 'building_N',
						op: FilterOps.EQUALS,
						value: '',
					},
				],
				groups:{
					g1:{rules:{}
					}
				},
			},
			filter: {},
			imodel: imodel,
			imotModel: imotModel,
			editImot: false,
			imotId: null,
			imotDataId: null,
		};
	},

	computed: {
		filter2: function(){
			if (!this.selectedUtility) return this.filter;
			const f2 = cloneDeep(this.filter);
			f2.groups = {
				constrains: {
					rules: {
						utility_code: {
							field: 'utility_code',
							op: 'eq',
							value: this.selectedUtility,
						}
					}
				}
			};
			return f2;
		},
	},

	watch: {
		imotId(val){
			this.imotDataId = null;
			if (val > 0) {
				imotModel.getLastSessionDataId(val, this.id).then(res=>{
					this.imotDataId = res;
				});
			}
		},
	},

	methods: {
		onEditImot($event){
			this.imotId = $event.id;
			this.editImot = true;
		},
		onAddImot(){
			this.imotId = 'new';
			this.editImot = true;
		},
		reloadImotList(){
			this.$refs.imotList.getDataFromApi();
		},
		getHiddenStationFields(row){
			const fields = [];
			if (row.VID_SGR != '0.15') {
				fields.push('sgri_2_zones');
				row.sgri_2_zones = 0;
			}
			switch (row.sgri_method) {
				case '1': fields.push('sgri_percent', 'sgri_percent_2', 'sgri_moshtnost'); break;
				case '2': fields.push('sgri_percent', 'sgri_percent_2'); break;
				case '3': fields.push('sgri_percent', 'sgri_percent_2'); break;
				case '4': fields.push('sgri_moshtnost'); break;
				case '5': fields.push('sgri_percent', 'sgri_percent_2', 'sgri_moshtnost'); break;
			}
			if (row.sgri_2_zones!=='1') {
				fields.push('sgri_percent_2');
			}
			return uniq(fields);
		},
		getHiddenImotFields(){
			const fields = [];
			if (this.$refs.EditForm.row.sgri_2_zones !== '1') {
				fields.push('station_zone');
			}
			return fields;
		},
	},

	provide: function () {
		return {
			imodel,
		};
	},
};
</script>

<style>
</style>