<template>
	<v-container fluid>
		<please-select-utility>
			<v-row dense>
				<v-col>
					<my-expand :expand.sync="expandSessionStation">
						<template #title>
							<div>Сесия:</div>
							<div style="max-width: 500px;" @click.stop>
								<session-select
									v-model="sessionId"
									:utility="selectedUtility"
									clearable solo hide-details dense flat
									class="flex-grow-1 ml-4"
									nav
								/>
							</div>

							<v-divider vertical class="mx-2" />

							<div>Аб. Станция:</div>
							<div style="max-width: 550px;" @click.stop>
								<station-select-all
									v-model="stationId"
									:session="sessionId"
									clearable solo hide-details dense flat
									class="flex-grow-1 ml-4"
								/>
							</div>
						</template>

						<template #title-right v-if="stationData">
							<station-take-dialog
								:station="stationId"
								:session="sessionId"
								:name="stationData.operator_name"
								@ready="getImotList"
							>
								<template v-slot:activator="{ on, attrs }" v-if="stationData.operator_name==''">
									<v-btn right small v-bind="attrs" v-on="on" class="ml-2" :disabled="sessionLocked">
										Поеми станцията
									</v-btn>
								</template>
								<template v-slot:activator="{ on, attrs }" v-else>
									<v-btn right small v-bind="attrs" v-on="on" class="ml-2" :disabled="sessionLocked" color="success">
										Отг.: {{ stationData.operator_name }}
									</v-btn>
								</template>
							</station-take-dialog>
							<station-edit-popup
								v-if="!sessionLocked"
								:station="stationId"
								:session="sessionId"
								@reload="getImotList"
							/>
							<btn-loading-promise
								v-if="!sessionLocked && isRightFull"
								:click="()=>model.removeStationFromSession(sessionId, stationId)"
								@ready="getImotList"
								@error="throwMe"
							>
								<template #default="{click,loading}">
									<confirm-dialog @confirm="click" color-confirm="red" icon-confirm="mdi-delete-circle" :max-width="600">
										<template v-slot:activator="{ on, attrs }">
											<v-btn v-bind="attrs" v-on="on" :loading="loading" icon small class="ml-2">
												<v-icon color="red">mdi-close-circle</v-icon>
											</v-btn>
										</template>
										<p class="body-1">Станцията ще бъде изключена от избраната сесия.</p>
									</confirm-dialog>
								</template>
							</btn-loading-promise>
						</template>

						<v-container v-if="sessionData && stationData" fluid>
							<pre><b>Сесия:</b> t_calculated: {{ sessionData.t_calculated }}, t_avg_building: {{ sessionData.t_avg_building }}, hot_water_per_day_per_person_liters: {{sessionData.hot_water_per_day_per_person_liters}}, max_hw_m3_to_mwh: {{sessionData.max_hw_m3_to_mwh}}, price_1_mwh: {{sessionData.price_1_mwh}}</pre>
							<station-info
								:station="stationRow"
								:data="stationData"
								:session="sessionData"
								@reload="getImotList"
							/>
						</v-container>

					</my-expand>
				</v-col>
			</v-row>

			<v-row dense v-show="imotLoading"><v-col><v-progress-linear indeterminate rounded /></v-col></v-row>

			<v-row dense v-if="lastStationIzr">
				<v-col>
					<v-alert type="info" outlined class="ma-0">
						Актуалната изравнителна сесия за тази станция е:<br>
						<router-link :to="{
							name: 'account',
							params: {
								utility: this.utility,
								session: lastStationIzr.id,
								station: this.station,
								imot: null,
							},
						}">
							<v-btn class="mt-2" link>
								<session-item-description :item="lastStationIzr" />
							</v-btn>
						</router-link>
					</v-alert>
				</v-col>
			</v-row>

			<v-row dense v-if="!imotLoading && sessionData && stationId && !stationData">
				<v-col>
					<v-alert v-if="deactivatedSessionData" type="warning" icon="mdi-alert" dense class="ma-0 mb-2">
						Последната редовна сесия за тази станция е от <year-month
							:year="parseInt(deactivatedSessionData.year)"
							:month="parseInt(deactivatedSessionData.month)"
						/>
					</v-alert>
					<btn-loading-promise v-if="canAddStationToSession"
						:click="()=>model.addStationToSession(sessionId, stationId)"
						@ready="getImotList"
						@error="throwMe"
					>
						<template #default="{click,loading}">
							<confirm-dialog @confirm="click" icon-confirm="mdi-plus-circle" :max-width="600">
								<template v-slot:activator="{ on, attrs }">
									<v-btn v-bind="attrs" v-on="on" :loading="loading" color="primary">
										<v-icon left>mdi-plus-circle</v-icon>
										Активирай станцията
									</v-btn>
								</template>
								<p class="body-1">Станцията ще бъде добавена към избраната сесия.</p>
							</confirm-dialog>
						</template>
					</btn-loading-promise>
				</v-col>
			</v-row>

			<v-row dense v-if="sessionData && stationData">
				<v-col>
					<v-toolbar flat rounded dense color="headings2">

						<v-btn v-if="navLink" small target="_blank" :href="navLink" class="mr-2" >
							<v-icon left>mdi-map-marker-radius</v-icon>
							Карта
						</v-btn>

						<station-data-values
							:session="sessionData"
							:station-id="stationData.station_id"
							@reload="getImotList"
							:readonly="sessionLocked"
						>
							<template v-slot:activator="{attrs,on}">
								<v-btn small v-bind="attrs" v-on="on" class="mr-2">
									<v-icon left>mdi-speedometer</v-icon>
									Данни станция
								</v-btn>
							</template>
						</station-data-values>

						<station-data-calc
							v-if="isMesechen || isIzravnitelna"
							:session="sessionData"
							:station-id="stationData.station_id"
							@reload="getImotList"
							:readonly="sessionLocked"
						>
							<template v-slot:activator="{attrs,on}">
								<v-btn small v-bind="attrs" v-on="on" class="mr-2">
									<v-icon left>mdi-calculator</v-icon>
									Ръчни данни
								</v-btn>
							</template>
						</station-data-calc>

						<hw-m3-to-mwh-dialog
							v-if="isMesechen && stationData.work_days_heat > 0"
							@ready="getImotList"
							@error="throwMe"
							:session="sessionId"
							:station="stationId"
							:imodel="model"
							:value-init="stationData.hw_m3_to_mwh_summer"
							:readonly="sessionLocked"
						>
							<template #activator="{attrs,on}">
								<v-btn small class="mr-2" v-bind="attrs" v-on="on">
									HwM3ToMwh
								</v-btn>
							</template>
						</hw-m3-to-mwh-dialog>

						<!-- hw-m3-to-mwh-dialog-izr -->

						<slujebni-dialog
							v-if="isMesechen"
							@ready="getImotList"
							:session="sessionId"
							:station="stationId"
							:imodel="model"
							:readonly="sessionLocked"
						>
							<template #activator="{attrs,on}">
								<v-btn small class="mr-2" v-bind="attrs" v-on="on">
									Служебни
								</v-btn>
							</template>
						</slujebni-dialog>

						<voda-dialog
							v-if="isMesechen || isIzravnitelna"
							@ready="getImotList"
							@error="throwMe"
							:session="sessionId"
							:station="stationId"
							:imodel="model"
							:readonly="sessionLocked"
							:izravnitelna="isIzravnitelna"
						>
							<template #activator="{attrs,on}">
								<v-btn small class="mr-2" v-bind="attrs" v-on="on">
									Вода
								</v-btn>
							</template>
						</voda-dialog>

						<msrs-dialog
							v-if="(isMesechen || isIzravnitelna) && stationData.work_days_heat > 0"
							@ready="getImotList"
							:session="sessionId"
							:station="stationId"
							:imodel="model"
							:msrs="stationData.msrs"
							:shtrang="stationData.shtrang_liri_percent"
							:deviceId="stationData.msrs_device_id"
							:readonly="sessionLocked"
						>
							<template #activator="{attrs,on}">
								<v-btn small class="mr-2" v-bind="attrs" v-on="on">
									МСРС + Нередовни + Щранг-лири
								</v-btn>
							</template>
						</msrs-dialog>

						<btn-loading-promise
							:click="()=>recalcConsumption()"
							@ready="getImotList"
							@error="throwMe"
							v-if="!sessionLocked"
						>
							<template #default="{click,loading}">
								<confirm-dialog @confirm="click">
									<template v-slot:activator="{ on, attrs }">
										<v-btn v-bind="attrs" v-on="on" small class="mr-2" :loading="loading" :disabled="loading">
											Консумация
										</v-btn>
									</template>
									<p class="body-1">Ще бъдат преизчислени консумациите на топлинна енергия за уредите в станцията</p>
								</confirm-dialog>
							</template>
						</btn-loading-promise>

						<q-max-dialog
							v-if="(isMesechen || isIzravnitelna) && stationData.qMax_devices"
							@ready="getImotList"
							:session="sessionId"
							:station="stationId"
							:imodel="model"
							:readonly="sessionLocked"
						>
							<template #activator="{attrs,on}">
								<v-btn small class="mr-2" v-bind="attrs" v-on="on">
									Q-max
								</v-btn>
							</template>
						</q-max-dialog>

						<btn-progress-confirm
							v-if="isIzravnitelna && !sessionLocked"
							:callback="()=>makeIzrPDFs()"
							@ready="getImotList"
							@error="throwMe"
							:button-props="{
								class:'mr-2',
								color: isIzrPDFsGenerated ? 'success' : '',
							}"
						>
							<template #button-content>
								<v-icon v-if="isIzrPDFsGenerated" left>mdi-check</v-icon>
								Сметки PDF
							</template>
							<template #confirm-content>
								<p class="body-1">Ще бъдат генерирани PDF файлове със сметките в станцията.</p>
								<p class="body-1">Файловете се свалят през FTP.</p>
							</template>
						</btn-progress-confirm>

						<smetki-dialog
							:session="sessionId"
							:station="stationId"
							:imodel="model"
							:izravnitelna="isIzravnitelna"
						>
							<template #activator="{attrs,on}">
								<v-btn small class="mr-2" v-bind="attrs" v-on="on">
									Сметки
								</v-btn>
							</template>
						</smetki-dialog>

						<station-otchet-dialog
							:session="sessionId"
							:station="stationId"
							:imodel="model"
							v-if="isIzravnitelna"
							@ready="getImotList"
						>
							<template #activator="{attrs,on}">
								<v-btn small class="mr-2" v-bind="attrs" v-on="on">
									Отчети
								</v-btn>
							</template>
						</station-otchet-dialog>

					</v-toolbar>
				</v-col>
			</v-row>

			<v-row dense v-if="sessionData && stationData && sdmodel.loaded">
				<v-col>
					<key-val-table-model :imodel="sdmodel" class="elevation-2" dense
						:data="{
							work_days_hw: stationData.work_days_hw,
							work_days_heat: stationData.work_days_heat,
							...(
								!isIzravnitelna && !isMesechen
								? {izr_hw_m3_to_mwh: stationData.izr_hw_m3_to_mwh}
								: {base_hw_power: stationData.base_hw_power}
							),
							reded_to_mwh: stationData.reded_to_mwh,
							msrs: stationData.msrs,
						}"
					/>
				</v-col>
			</v-row>

			<v-row dense v-if="stationData && sessionId && stationId && !imotLoading">

				<v-col cols="3">
					<imot-list
						ref="imotList"
						v-model="imotId"
						:items="imotList"
						@click="focusFirstDevice = false;"
					/>
					<br>
					<router-link
						target="_blank"
						class="mr-2"
						:to="{
							name: 'reports',
							params: {
								report: 'report1',
								session: sessionId,
								station: stationId,
							}
						}"
					>
						<v-btn small>Report 1</v-btn>
					</router-link>
					<router-link
						target="_blank"
						class="mr-2"
						:to="{
							name: 'reportDevListSearch',
							params: {
								utility: selectedUtility,
								session: sessionId,
								station: stationId,
							},
						}"
					>
						<v-btn small>Списък показания</v-btn>
					</router-link>
					<v-btn
						v-if="isIzravnitelna && imotId"
						small
						target="_blank"
						class="mr-2"
						link
						:href="model.getRequestURL('izrSmetkaPreview', {
							session: sessionId,
							station: stationId,
							imot: imotId,
						})"
					>Сметка (preview)</v-btn>
					<etalon-list :items="etalonList" />
				</v-col>

				<v-col cols="9">
					<v-row dense v-if="selectedImot">
						<v-col>
							<imot-info
								:imot="selectedImot"
								:documents.sync="imotDocuments"
								@reload="getImotList"
								:locked="isSessionReadOnly"
								:expand.sync="expandImotClient"
								:mesechen="isMesechen"
								:izravnitelna="isIzravnitelna"
								:korekcionna="isKorekcionna"
							/>
						</v-col>
					</v-row>
					<v-row dense v-if="selectedImot">
						<v-col>
							<key-val-table-model
								class="elevation-2" dense
								:imodel="idmodel"
								:data="{
									prognozen_dial_bgv: selectedImot.data.prognozen_dial_bgv,
									prognozen_dial_heat: selectedImot.data.prognozen_dial_heat,
									prognozen_dial_sgri: selectedImot.data.prognozen_dial_sgri,
									volume: selectedImot.data.volume,
									...(selectedImot.data.hot_water_na_brl=='1' ? {
										broi_lica: selectedImot.data.broi_lica,
									} : {}),
									e_hot_water: selectedImot.data.e_hot_water,
									e_sgri: selectedImot.data.e_sgri,
									e_heat: selectedImot.data.e_heat,
								}"
							>
								<template #td.broi_lica="{val}">
									<span class="red rounded font-weight-bold white--text px-2 py-1">{{ val }}</span>
								</template>
							</key-val-table-model>
						</v-col>
					</v-row>
					<v-row dense v-if="selectedImot?.OtchetNotes?.length > 0">
						<v-col>
							<v-alert text outlined type="info" class="mb-0" dense>
								Бележки от отчет:
								<dl v-for="(note, index) in selectedImot.OtchetNotes" :key="index">
									<dt class="font-weight-bold font-italic"><date-format :date="note.date" /></dt>
									<dd class="text-pre-line">{{note.notes}}</dd>
								</dl>
							</v-alert>
						</v-col>
					</v-row>
					<v-row dense v-if="selectedImot">
						<v-col>
							<device-data-list
								ref="deviceDataList"
								:imot="imotId"
								:session="sessionId"
								:station="stationId"
								@reload="getImotData"
								@saved="focusFirstDevice = true; $refs.imotList.advance()"
								@pageup="gotoImotPrev"
								@pagedown="gotoImotNext"
							>
								<template #actions-left>
									<span class="mr-2 d-inline-block">
										<CreateUP
											:imot="imotId"
											@reload="onCreateUPReload"
										/>
									</span>
								</template>
							</device-data-list>
						</v-col>
					</v-row>
					<v-row dense v-if="selectedImot">
						<v-col>
							<device-list
								:loading="deviceLoading"
								:devices="deviceList"
								:session="sessionId"
								:imot="imotId"
								:locked="isSessionReadOnly"
								@add="addDeviceToSession($event)"
								@reload="getImotData"
							/>
						</v-col>
					</v-row>
					<v-row dense v-if="selectedImot && isIzravnitelna">
						<v-col>
							<v-card>
								<v-card-title class="text-subtitle-1 headings py-2 pl-4">Брой лица по месеци</v-card-title>
								<v-divider />
								<izr-brl-list
									:session="sessionId"
									:imot="imotId"
									:station="stationId"
									:locked="sessionLocked"
									@reload="getImotData"
								/>
							</v-card>
						</v-col>
					</v-row>
				</v-col>

			</v-row>
		</please-select-utility>
	</v-container>
</template>

<script>
import SelectedUtilityMixin from '@/ittijs/SelectedUtilityMixin';
import RouteSyncMixin from '@/ittijs/RouteSyncMixin';
import SessionSelect from '../sessions/SessionSelect.vue';
import Model from './Model';
import StationDataModel from '../stations/ModelData';
import ImotDataModel from '../imot_data/Model';
import ImotInfo from './ImotInfo.vue';
import ImotList from './ImotList.vue';
import StationInfo from './StationInfo.vue';
import DeviceDataList from '@/ittijs/components/DeviceDataList/DeviceDataList';
import DeviceList from "./DeviceList";
import StationDataValues from "@/views/clients_screen/StationDataValues";
import EtalonList from "@/views/clients_screen/EtalonList";
import BtnLoadingPromise from "@/ittijs/components/BtnLoadingPromise";
import SlujebniDialog from "@/views/clients_screen/SlujebniDialog";
import ConfirmDialog from "@/ittijs/ConfirmDialog";
import StationSelectAll from "@/views/stations/StationSelectAll";
import MsrsDialog from "@/views/clients_screen/MsrsDialog";
import VodaDialog from "@/views/clients_screen/VodaDialog";
import HwM3ToMwhDialog from "@/views/clients_screen/HwM3ToMwhDialog";
import StationEditPopup from "@/views/clients_screen/StationEditPopup";
import MyExpand from "@/ittijs/components/MyExpand";
import StationDataCalc from "@/views/clients_screen/StationDataCalc";
import Mousetrap from 'mousetrap';
import StationTakeDialog from "@/views/clients_screen/StationTakeDialog";
import QMaxDialog from "@/views/clients_screen/QMaxDialog";
import YearMonth from "@/ittijs/components/YearMonth";
import SmetkiDialog from "@/views/clients_screen/SmetkiDialog";
import BtnProgressConfirm from "@/ittijs/components/BtnProgressConfirm";
import PleaseSelectUtility from "@/ittijs/components/PleaseSelectUtility";
import IzrBrlList from "@/views/clients_screen/IzrBrlList";
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";
import {RolesEnum} from "@/ittijs/Authorization";
import ThrowMeMixin from "@/ittijs/ThrowMeMixin";
import SessionItemDescription from "@/views/sessions/SessionItemDescription";
import CreateUP from "@/ittijs/components/UvedomitelniPisma/CreateUP.vue";
import KeyValTableModel from "@/ittijs/components/KeyValTableModel.vue";
import {debounceAsync, stationMapUrl} from "@/ittijs/utils";
import DateFormat from "@/ittijs/components/DateFormat.vue";
import StationOtchetDialog from "@/views/clients_screen/StationOtchetDialog.vue";

const model = ITTIModelProvider.getModel(Model);
const sdmodel = ITTIModelProvider.getModel(StationDataModel);
const idmodel = ITTIModelProvider.getModel(ImotDataModel);

export default {

	components: {
		StationOtchetDialog,
		DateFormat,
		KeyValTableModel,
		CreateUP,
		SessionItemDescription,
		IzrBrlList,
		PleaseSelectUtility,
		BtnProgressConfirm,
		SmetkiDialog,
		YearMonth,
		QMaxDialog,
		StationTakeDialog,
		StationDataCalc,
		MyExpand,
		StationEditPopup,
		HwM3ToMwhDialog,
		VodaDialog,
		MsrsDialog,
		StationSelectAll,
		ConfirmDialog,
		SlujebniDialog,
		BtnLoadingPromise,
		EtalonList,
		StationDataValues,
		DeviceList,
		DeviceDataList,
		SessionSelect,
		ImotInfo,
		ImotList,
		StationInfo,
	},

	mixins: [
		SelectedUtilityMixin,
		RouteSyncMixin({
			utility: 'selectedUtility',
			session: 'sessionId',
			station: 'stationId',
			imot: 'imotId',
		}),
		ThrowMeMixin,
	],

	mounted() {
		Mousetrap.bind('pageup', ()=>{
			this.gotoImotPrev();
			return false;
		});
		Mousetrap.bind('pagedown', ()=>{
			this.gotoImotNext();
			return false;
		});
	},

	inject: ['authorization'],

	watch: {
		sessionId(val){
			if (!val) {
				this.stationId = null;
				this.imotId = null;
			}
			this.clearDataBeforeGetImotList();
			this.getImotListDebounce();
		},
		stationId(val){
			if (!val) {
				this.imotId = null;
			}
			this.clearDataBeforeGetImotList();
			this.getImotListDebounce();
		},
		imotId(val){
			val && this.getImotData();
		},
	},

	props: {
		utility: {},
		session: {},
		station: {},
		imot: {},
	},

	data(){
		return {
			getImotListDebounce: debounceAsync(this.getImotList, 100),

			model,
			sdmodel,
			idmodel,
			sessionId: null,
			stationId: null,
			sessionData: null,
			stationRow: null, // n_station
			stationData: null, // n_station_data
			deactivatedSessionData: null, // ако не е null, станцията е спряна по някое време
			isIzrPDFsGenerated: null, // дали са генерирани PDF-ите за изравнителната сесия
			etalonList: null,
			lastStationIzr: null,

			expandSessionStation: false,
			expandImotClient: false,

			imotList: [],
			imotId: null,
			imotLoading: false,
			deviceList: [],
			imotDocuments: [],
			deviceLoading: false,

			/**
			 * flag to focus first device after saving the previous imot and going to the next
			 */
			focusFirstDevice: false,
		}
	},

	computed: {
		selectedImot(){
			return this.imotList.find(i=>i.data.imot_id==this.imotId) || null;
		},
		sessionLocked(){
			return !this.sessionData || this.sessionData.locked=='1' || false;
		},
		isRightFull(){
			return this.authorization.hasRole(RolesEnum.OTCHETI_FULL);
		},
		isSessionReadOnly(){
			if (!this.sessionLocked) return false;
			if (!this.authorization.hasRole(RolesEnum.OTCHETI_FULL)) return false;
			return true;
		},
		isMesechen(){
			return this.stationData?.mesechen==='1';
		},
		isIzravnitelna(){
			return this.sessionData?.izr_sess!='0';
		},
		isKorekcionna(){
			return this.sessionData?.izr_sess=='2';
		},
		canAddStationToSession(){
			if (this.sessionLocked) return false;
			if (this.lastStationIzr && parseInt(this.lastStationIzr.id) > this.sessionId) {
				return false;
			}
			return true;
		},
		navLink() {
			return stationMapUrl(this.stationRow);
		},
	},

	methods: {
		clearDataBeforeGetImotList(){
			this.imotList = [];
			this.sessionData = null;
			this.deactivatedSessionData = null;
			this.isIzrPDFsGenerated = null;
			this.stationRow = null;
			this.stationData = null;
			this.etalonList = null;
			this.lastStationIzr = null;
		},
		async getImotList(){
			if (this.imotLoading) return;
			this.imotLoading = true;
			//this.imotId = null; // breaks router navigation
			this.clearDataBeforeGetImotList();
			if (this.sessionId > 0 && this.stationId > 0) {
				try {
					const res = await model.getBySessionStation(this.sessionId, this.stationId);
					if (res.stationData===null) {
						// станцията не е активна за тази сесия
						this.imotId = null;
					}
					this.imotList = res.imotList;
					this.sessionData = res.session;
					this.deactivatedSessionData = res.deactivatedSession;
					this.isIzrPDFsGenerated = res.isIzrPDFsGenerated;
					this.stationRow = res.station;
					this.stationData = res.stationData;
					this.etalonList = res.etalonList;
					this.lastStationIzr = res.lastStationIzr;
					// unset imotId if not in current list
					if (this.imotId!='') {
						if (!res.imotList.find(el=>el.data.imot_id == this.imotId)) {
							this.imotId = null;
						}
						else {
							await this.getImotData();
						}
					}
				}
				catch (e) {
					console.log(e);
				}
			}
			this.imotLoading = false;
		},
		async getImotData(){
			if (this.deviceLoading) return;
			this.deviceLoading = true;
			this.deviceList = [];
			this.imotDocuments = [];
			if (this.imotId && this.sessionId) {
				try {
					const res = await model.getImotData(this.imotId, this.sessionId, this.stationId);

					const imot = this.imotList.find(i=>i.data.imot_id==this.imotId);
					if (imot) {
						imot.imot = res.imot;
						imot.data = res.imot_data;
						imot.history = res.client_history;
						imot.UPList = res.up_list;
						imot.OtchetNotes = res.otchet_notes;
					}

					if (Array.isArray(res.devices)) {
						this.deviceList = res.devices;
					}
					else {
						this.deviceList = [];
					}
					if (Array.isArray(res.documents)) {
						this.imotDocuments = res.documents;
					}
					else {
						this.imotDocuments = [];
					}
				}
				catch (e) {
					console.log(e);
				}
			}
			this.deviceLoading = false;

			if (this.focusFirstDevice) {
				this.focusFirstDevice = false;
				this.$nextTick(()=>{
					this.$refs.deviceDataList.focusFirst();
				});
			}
		},
		async addDeviceToSession(deviceId){
			await model.addDeviceToSession(deviceId, this.sessionId);
			await this.getImotData();
			await this.$refs.deviceDataList.reload();
		},
		async onCreateUPReload(){
			await this.getImotData();
			await this.$refs.deviceDataList.reload();
		},
		parseFloat,
		async recalcConsumption(){
			return await model.recalcConsumption(this.sessionId, this.stationId);
		},
		makeIzrPDFs(){
			return model.makeIzrPDFs(this.sessionId, this.stationId);
		},
		gotoImotPrev(){
			this.$refs.imotList.gotoPrev();
		},
		gotoImotNext(){
			this.$refs.imotList.gotoNext();
		},
	},

}
</script>