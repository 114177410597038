<template>
	<v-dialog v-model="open" width="700">
		<template v-slot:activator="{ on, attrs }">
			<slot name="activator" v-bind="{ on, attrs }">
				<v-btn right icon small v-bind="attrs" v-on="on">
					<v-icon dense :color="activatorColor">{{ activatorIcon }}</v-icon>
				</v-btn>
			</slot>
		</template>
		<template v-slot:default="">
			<v-card>
				<dev-form-replace
					v-if="open"
					max-content-height="calc(90vh - 120px)"
					:device="device.id"
					:session="session"
					:init="initData"
					:title="title"
					@close="open = false"
					@saveclose="open = false; $emit('reload')"
				/>
			</v-card>
		</template>
	</v-dialog>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */
import {phpDate} from "@/ittijs/utils";
import DevFormReplace from "@/ittijs/components/DeviceDataList/DevFormReplace.vue";

export default {
	components: {
		DevFormReplace,
	},
	props: {
		'title': String,
		'device': {},
		'session': {},
		'copy': {}, // when inserting, use this device as template
	},
	data(){
		return {
			open: false,
		}
	},
	computed: {
		initData(){
			if (this.copy) {
				const res = {...this.copy};
				delete res.id;
				res['mount_date'] = phpDate('Y-m-d');
				return res;
			}
			else {
				return {
				}
			}
		},
		activatorColor(){
			return 'green';
		},
		activatorIcon(){
			return 'mdi-swap-horizontal-circle';
		},
	},
}
</script>