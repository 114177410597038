<template>
	<my-dialog title="МСРС, Нередовни, Щранг лири" @open="onOpen" @close="onClose" ref="dialog" width="700">
		<template #activator="scope">
			<slot name="activator" v-bind="scope" />
		</template>

		<v-form ref="form" v-if="!isInvalid">
			<v-container>
				<v-row>
					<v-col>
						<v-text-field
							outlined dense
							hide-details
							label="МСРС"
							v-model="msrsInput"
						/>
					</v-col>
					<v-col>
						<v-text-field
							outlined dense
							suffix="%"
							hide-details
							label="КОРЕКЦИЯ НА ЩРАНГ ЛИРИ"
							v-model="shtrangInput"
						/>
					</v-col>
				</v-row>
			</v-container>
		</v-form>

		<v-divider v-if="!isInvalid" />

		<div v-if="loading" class="text-center pa-4">
			<v-progress-circular indeterminate color="grey" />
		</div>

		<v-alert text dense type="error" v-if="isInvalid">
			Има уреди с липсващо показание!
		</v-alert>

		<v-simple-table v-if="!loading" height="400" fixed-header ref="table">
			<thead>
			<tr>
				<th>Имот</th>
				<th>Уред</th>
				<th class="text-right" v-if="!isInvalid">Разлика</th>
				<th class="text-right">Мощност</th>
				<th class="text-right" v-if="!isInvalid">МСРС</th>
				<th v-if="!isInvalid"></th>
			</tr>
			</thead>
			<tbody ref="tbody">
			<tr v-for="(item, i) in filteredList" :key="i" :class="{
				'accent lighten-2': isHighlight(item)
			}">
				<td>
					{{item.imot_N}}<br>
					{{item.location}}
				</td>
				<td>
					{{item.device_N}}<br>
					{{item.room}}
				</td>
				<td class="text-right" v-if="!isInvalid">{{item.diff}}</td>
				<td class="text-right">{{item.radiator_power}}</td>
				<td class="text-right" v-if="!isInvalid">{{item.msrs}}</td>
				<td width="1%" v-if="!isInvalid">
					<v-btn color="secondary" outlined @click="choose(item)">Избери</v-btn>
				</td>
			</tr>
			</tbody>
		</v-simple-table>

		<v-divider />

		<div class="d-flex pa-2">
			<v-spacer/>
			<v-btn text @click="close">Затвори</v-btn>
			<btn-loading-promise
				v-if="!readonly && !isInvalid"
				:click="()=>update()"
				@ready="$emit('ready'); close();"
			>
				<template #default="{click,loading}">
					<v-btn color="primary" @click="click" :loading="loading" class="ml-2">
						Потвърди
					</v-btn>
				</template>
			</btn-loading-promise>
		</div>

	</my-dialog>
</template>

<script>
import MyDialog from "@/ittijs/components/MyDialog";
import BtnLoadingPromise from "@/ittijs/components/BtnLoadingPromise";
import IModelMixin from "@/ittijs/IModelMixin";
export default {
	components: {BtnLoadingPromise, MyDialog},
	mixins: [IModelMixin],
	props: [
		'session',
		'station',
		'msrs',
		'shtrang',
		'readonly',
		'deviceId',
	],
	data(){
		return {
			msrsInput: null,
			shtrangInput: null,
			deviceIdInput: null,
			loading: false,
			items: [],
		}
	},
	computed: {
		isInvalid(){
			return this.items.some(item => item.msrs === null);
		},
		filteredList(){
			// if there are nulls in the list, show only them
			return this.items.filter(item => {
				if (this.isInvalid) {
					return item.msrs === null;
				}
				return true;
			});
		},
	},
	methods: {
		async onOpen(){
			this.msrsInput = this.msrs;
			this.shtrangInput = this.shtrang;
			this.deviceIdInput = this.deviceId;
			this.items = [];
			this.loading = true;
			this.$refs.dialog.doOpen();
			this.items = await this.imodel.getMSRSList(this.station, this.session);
			this.loading = false;
			this.$nextTick(()=>{
				const scrollContainer = this.$refs.table.$el.children[0];
				const row = this.$refs.tbody
					&& [...this.$refs.tbody.children].find(el=>el.classList.contains('accent'))
					|| null;
				if (row) {
					scrollContainer.scrollTop = row.offsetTop - this.$refs.tbody.offsetTop;
				}
			});
		},
		onClose(){
			// todo
		},
		close(){
			this.$refs.dialog.doClose();
		},
		choose(item){
			this.msrsInput = item.msrs;
			this.deviceIdInput = item.device_id;
		},
		async update(){
			await this.imodel.updateMsrsShtrang(
				this.session,
				this.station,
				this.msrsInput,
				this.shtrangInput,
				this.deviceIdInput,
			);
		},
		isHighlight(item){
			return item.device_id == this.deviceIdInput;
		},
	},
}
</script>