var render = function render(){var _vm=this,_c=_vm._self._c;return _c('data-loader',{attrs:{"callback":_vm.loadData,"data":_vm.data},on:{"update:data":function($event){_vm.data=$event}},scopedSlots:_vm._u([{key:"default",fn:function(){return [(_vm.service)?_c('my-dialog',{attrs:{"max-width":"1200","title":"Обработка на монтажна дейност"},scopedSlots:_vm._u([{key:"activator",fn:function({attrs,on}){return [_vm._t("activator",function(){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"fab":"","depressed":"","small":"","color":"warning"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-tools")]),(_vm.remaining)?_c('v-badge',{attrs:{"content":_vm.remaining + 1}}):_vm._e()],1)]},null,{attrs,on})]}},{key:"actions",fn:function(){return [(_vm.remaining)?_c('span',{staticClass:"warning white--text rounded px-1"},[_vm._v(" има още "+_vm._s(_vm.remaining)+" извършени дейности ")]):_vm._e(),_c('v-spacer'),_c('edit-buttons',{on:{"close":_vm.doClose,"save":_vm.doSave}})]},proxy:true}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.service.notes_operator!='' || _vm.service.notes_montajnik!='')?_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-textarea',{attrs:{"readonly":"","outlined":"","dense":"","hide-details":"","value":_vm.service.notes_operator,"label":"Бележки оператор"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-textarea',{attrs:{"readonly":"","outlined":"","dense":"","hide-details":"","value":_vm.service.notes_montajnik,"label":"Бележки монтажник"}})],1)],1)],1):_vm._e(),(_vm.dialog && _vm.service.work_type === 'replace')?_c('dev-form-replace',{ref:"form",attrs:{"model":_vm.modelReplace,"hide-title":"","edit-buttons":"","third-col":"","right-col-width":400,"third-col-attrs":{
					style: 'flex: 0 1 400px',
					class: 'pa-1'
				},"session":_vm.session,"device":_vm.data.device.id},on:{"save":_vm.onSaved},scopedSlots:_vm._u([_vm._l((_vm.srvFieldMap),function(n_srv,n_dev){return {key:`third.${n_dev}`,fn:function({row,imodel}){return [_c('div',{key:n_dev,staticClass:"d-flex"},[_c('v-btn',{attrs:{"icon":"","color":row[n_dev] == _vm.service[n_srv] || _vm.service[n_srv] == null ? 'grey lighten-1' : 'primary'},on:{"click":function($event){return _vm.$set(row, n_dev, _vm.service[n_srv])}}},[_c('v-icon',[_vm._v("mdi-arrow-left-bold-box")])],1),_c('v-switch-case',{attrs:{"value":n_dev},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('model-input',{attrs:{"imodel":imodel,"name":n_dev,"value":_vm.service[n_srv],"outlined":"","dense":"","readonly":true,"disabled":true,"label":""}})]},proxy:true},{key:"device_model_id",fn:function(){return [_c('device-model-select-base',{attrs:{"value":_vm.service[n_srv],"outlined":"","dense":"","readonly":true,"disabled":true,"label":""}})]},proxy:true}],null,true)})],1)]}}}),{key:"after-fields",fn:function({row}){return [_c('div',{staticClass:"text-right py-2"},[_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.copyAll(row)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-left-bold-box")]),_vm._v(" Прехвърли всичко ")],1)],1)]}}],null,true)}):(_vm.dialog)?_c('dev-form-edit',{ref:"form",attrs:{"model":_vm.modelEdit,"hide-title":"","edit-buttons":"","third-col":"","right-col-width":400,"third-col-attrs":{
					style: 'flex: 0 1 400px',
					class: 'pa-1'
				},"session":_vm.session,"device":_vm.data.device.id},on:{"save":_vm.onSaved},scopedSlots:_vm._u([_vm._l((_vm.srvFieldMap),function(n_srv,n_dev){return {key:`third.${n_dev}`,fn:function({row,imodel}){return [_c('div',{key:n_dev,staticClass:"d-flex"},[_c('v-btn',{attrs:{"icon":"","color":row[n_dev] == _vm.service[n_srv] || _vm.service[n_srv] == null ? 'grey lighten-1' : 'primary'},on:{"click":function($event){return _vm.$set(row, n_dev, _vm.service[n_srv])}}},[_c('v-icon',[_vm._v("mdi-arrow-left-bold-box")])],1),_c('v-switch-case',{attrs:{"value":n_dev},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('model-input',{attrs:{"imodel":imodel,"name":n_dev,"value":_vm.service[n_srv],"outlined":"","dense":"","readonly":true,"disabled":true,"label":""}})]},proxy:true},{key:"device_model_id",fn:function(){return [_c('device-model-select-base',{attrs:{"value":_vm.service[n_srv],"outlined":"","dense":"","readonly":true,"disabled":true,"label":""}})]},proxy:true}],null,true)})],1)]}}}),{key:"after-fields",fn:function({row}){return [_c('div',{staticClass:"text-right py-2"},[_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.copyAll(row)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-left-bold-box")]),_vm._v(" Прехвърли всичко ")],1)],1)]}}],null,true)}):_vm._e()],1):_vm._e()]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }