<template>
	<my-dialog title="Qmax" @open="onOpen" @close="onClose" ref="dialog" width="700">
		<template #activator="scope">
			<slot name="activator" v-bind="scope" />
		</template>

		<div v-if="loading" class="text-center pa-4">
			<v-progress-circular indeterminate color="grey" />
		</div>

		<v-radio-group v-model="value">
			<v-simple-table v-if="!loading" height="400" fixed-header ref="table">
				<thead>
				<tr>
					<th>Имот</th>
					<th>Уред</th>
					<th>Тип</th>
					<th>Разлика</th>
					<th class="text-right">Над Max</th>
					<th>Вид отчет</th>
					<th></th>
				</tr>
				</thead>
				<tbody ref="tbody">
				<tr v-for="(item, i) in items" :key="i">
					<td>
						{{item.imot_N}}<br>
						{{item.location}}
					</td>
					<td>
						{{item.device_N}}<br>
						{{item.room}}
					</td>
					<td><field-value :imodel="MDeviceData" name="n_device_data.device_type_code" :value="item.device_type_code" /></td>
					<td class="text-right">{{ parseFloat(item.value_diff).toFixed(0) }}</td>
					<td class="text-right">{{ parseFloat(item.diff).toFixed(6) }}</td>
					<td><field-value :imodel="MDeviceData" name="n_device_data.otchet_type" :value="item.otchet_type" /></td>
					<td width="1%">
						<v-radio :value="item.id" />
					</td>
				</tr>
				</tbody>
			</v-simple-table>
		</v-radio-group>

		<v-divider />

		<div class="d-flex pa-2">
			<v-spacer/>
			<v-btn text @click="close">Откажи</v-btn>
			<btn-loading-promise
				v-if="!readonly"
				:click="()=>update()"
				@ready="$emit('ready'); close();"
			>
				<template #default="{click,loading}">
					<v-btn color="primary" @click="click" :loading="loading" class="ml-2">
						Потвърди
					</v-btn>
				</template>
			</btn-loading-promise>
		</div>

	</my-dialog>
</template>

<script>
import MyDialog from "@/ittijs/components/MyDialog";
import BtnLoadingPromise from "@/ittijs/components/BtnLoadingPromise";
import IModelMixin from "@/ittijs/IModelMixin";
import DDModel from "../report/deviceData/Model";
import FieldValue from "@/ittijs/components/FieldValue";

const MDeviceData = new DDModel;

export default {
	components: {FieldValue, BtnLoadingPromise, MyDialog},
	mixins: [IModelMixin],
	props: [
		'session',
		'station',
		'readonly',
	],
	data(){
		return {
			loading: false,
			QMaxData: null,
			value: null,
			MDeviceData,
		}
	},
	computed: {
		items(){
			return this.QMaxData && this.QMaxData.devices || [];
		},
	},
	methods: {
		async onOpen(){
			this.QMaxData = null;
			this.value = null;
			this.loading = true;
			this.$refs.dialog.doOpen();
			this.QMaxData = await this.imodel.getQMaxDevices(this.station, this.session);
			if (this.QMaxData && this.QMaxData.FirstId) {
				this.value = this.QMaxData.FirstId;
			}
			this.loading = false;
		},
		onClose(){
			// todo
		},
		close(){
			this.$refs.dialog.doClose();
		},
		async update(){
			await this.imodel.updateQMax(this.session, this.station, this.value);
		},
	},
}
</script>