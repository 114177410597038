<template>
	<my-dialog title="Вода" @open="onOpen" ref="dialog" width="900">
		<template #activator="scope">
			<slot name="activator" v-bind="scope" />
		</template>

		<div v-if="loading" class="text-center pa-4">
			<v-progress-circular indeterminate color="grey" />
		</div>

		<div v-else>
			<v-container>

				<v-alert type="info" dense>
					Уверете се, че стойностите за водомери по имоти са въведени!
				</v-alert>

				<v-simple-table dense>
					<thead>
					<tr>
						<th>По общ водомер</th>
						<th>Работни дни с вода</th>
						<th>Дни с неизправен водомер</th>
						<th>Водомер калкулиран</th>
						<th>Сума водомери по имоти</th>
						<th>Сума по брой лица</th>
					</tr>
					</thead>
					<tbody>
					<tr>
						<td>{{data.vodomer_hot_value}} м³</td>
						<td>{{data.work_days_hw}}</td>
						<td>{{data.work_days_wo_hot_vodomer}}</td>
						<td>{{data.vodomer_hot_calculated}} м³</td>
						<td>{{data.sum_vodomeri}} м³</td>
						<td>{{data.sum_brl}} м³</td>
					</tr>
					</tbody>
				</v-simple-table>

				<v-divider class="my-2" />

				<v-row>
					<v-col cols="4" class="text-right">
						Общ + калкулиран
						<v-btn outlined @click="valueInput = valFromStation.toString()">{{ valFromStation }} м³</v-btn>
					</v-col>
					<v-col cols="4" class="text-center" align-self="center" :class="{
						'red--text' : diffTooBig,
					}">
						<v-icon class="mr-4" color="success">{{ diffTooBig ? 'mdi-blank' : 'mdi-arrow-left' }}</v-icon>
						{{ diff > 0 ? '+' + diff : diff }} %
						<v-icon class="ml-4" color="success">{{ diffTooBig ? 'mdi-arrow-right' : 'mdi-blank' }}</v-icon>
					</v-col>
					<v-col cols="4" class="text-left">
						<v-btn outlined @click="valueInput = valFromImoti.toString()">{{ valFromImoti }} м³</v-btn>
						Водомери + БРЛ
					</v-col>
				</v-row>

				<v-row>
					<v-col class="d-flex justify-center">
						<v-form ref="form">
							<decimal-input
								label="Вода за разпределение" suffix="m³" v-model="valueInput"
								:rules="rules"
								outlined dense style="width: 250px;" class="flex-grow-0"
								hide-details="auto"
							/>
						</v-form>
					</v-col>
				</v-row>

				<v-row v-if="izravnitelna && data.izravnitelna">
					<v-col>
						<v-divider />
						<v-simple-table dense>
							<thead>
							<tr>
								<th>Месец</th>
								<th class="text-right"><field-label :imodel="sdmodel" name="work_days_hw" /></th>
								<th class="text-right"><field-label :imodel="sdmodel" name="vodomer_hot_value" /></th>
								<th class="text-right"><field-label :imodel="sdmodel" name="work_days_wo_hot_vodomer" /></th>
								<th class="text-right"><field-label :imodel="sdmodel" name="vodomer_hot_calculated" /></th>
							</tr>
							</thead>
							<tbody>
							<tr v-for="(item, index) in data.izravnitelna" :key="index">
								<td><year-month abbr :year="parseInt(item.year)" :month="parseInt(item.month)" /></td>
								<td class="text-right"><field-value :imodel="sdmodel" name="work_days_hw"             :value="item.work_days_hw"             /></td>
								<td class="text-right"><field-value :imodel="sdmodel" name="vodomer_hot_value"        :value="item.vodomer_hot_value"        /></td>
								<td class="text-right"><field-value :imodel="sdmodel" name="work_days_wo_hot_vodomer" :value="item.work_days_wo_hot_vodomer" /></td>
								<td class="text-right"><field-value :imodel="sdmodel" name="vodomer_hot_calculated"   :value="item.vodomer_hot_calculated"   /></td>
							</tr>
							</tbody>
						</v-simple-table>
					</v-col>
				</v-row>

			</v-container>
		</div>

		<v-divider />

		<div class="d-flex pa-2">
			<v-spacer/>
			<v-btn text @click="close">Затвори</v-btn>
			<btn-loading-promise
				v-if="!readonly"
				:click="()=>update()"
				@ready="$emit('ready'); close();"
				@error="$emit('error', $event)"
			>
				<template #default="{click,loading}">
					<v-btn color="primary" @click="click" :loading="loading" class="ml-2">
						Потвърди
					</v-btn>
				</template>
			</btn-loading-promise>
		</div>

	</my-dialog>
</template>

<script>
import MyDialog from "@/ittijs/components/MyDialog";
import BtnLoadingPromise from "@/ittijs/components/BtnLoadingPromise";
import IModelMixin from "@/ittijs/IModelMixin";
import DecimalInput from "@/ittijs/Inputs/DecimalInput";
import Validators from "@/ittijs/Validators";
import FieldValue from "@/ittijs/components/FieldValue";
import YearMonth from "@/ittijs/components/YearMonth";
import FieldLabel from "@/ittijs/components/FieldLabel";
import SDModel from "@/views/station_data/Model";
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";

const sdmodel = ITTIModelProvider.getModel(SDModel);

export default {
	mixins: [IModelMixin],
	components: {
		FieldLabel,
		YearMonth,
		FieldValue,
		DecimalInput,
		BtnLoadingPromise,
		MyDialog,
	},
	props: [
		'session',
		'station',
		'readonly',
		'izravnitelna',
	],
	data(){
		return {
			sdmodel,
			data: {},
			loading: false,
			valueInput: '',
			rules: [
				Validators.decimal(9, 3),
				Validators.required(),
			],
		}
	},
	computed: {
		diff(){
			const diff = (this.valFromStation / this.valFromImoti) * 100 - 100;
			return diff.toFixed(1);
		},
		diffTooBig(){
			return this.diff >= 30 || this.diff <= -30;
		},
		valFromStation(){
			return (this.data.vodomer_hot_value + this.data.vodomer_hot_calculated).toFixed(3);
		},
		valFromImoti(){
			return (this.data.sum_vodomeri + this.data.sum_brl).toFixed(3);
		},
	},
	methods: {
		async onOpen(){
			this.valueInput = '';
			this.data = {};
			this.loading = true;
			this.data = await this.imodel.getVodaData(this.session, this.station);
			this.valueInput = this.data.voda_za_razpr_m3 && this.data.voda_za_razpr_m3.toString() || '';
			this.loading = false;
		},
		close(){
			this.$refs.dialog.doClose();
		},
		async update(){
			if (!this.$refs.form.validate()) throw "Невалидна стойност"; // message not used
			return await this.imodel.updateVodaZaRazpr(this.session, this.station, this.valueInput);
		},
	},
}
</script>