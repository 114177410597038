<template>
	<v-dialog max-width="700" v-model="open">
		<template v-slot:activator="{ on, attrs }">
			<v-btn right icon small v-bind="attrs" v-on="on" class="ml-2">
				<v-icon dense color="primary">mdi-pencil</v-icon>
			</v-btn>
		</template>
		<template v-slot:default="">
			<v-card>
				<edit-column
					max-content-height="calc(90vh - 120px)"
					v-if="open"
					title="Абонатна станция"
					:params="{station_id:station,session_id:session}"
					@close="open = false"
					@saveclose="open = false; $emit('reload')"
					:imodel="model"
					:edit-button-options="{showDelete:false}"
					:fields="[
						'address',
						'VID_SGR',
						'sgri_2_zones',
						'sgri_method',
						'sgri_percent',
						'sgri_percent_2',
						'sgri_moshtnost',
						'water_type',
						'domoupravitel_name',
						'domoupravitel_phone',
						'domoupravitel_email',
						'authorized_persons',
						'notes',
					]"
					:hidden-fields-callback="getHiddenStationFields"
				>
				</edit-column>
			</v-card>
		</template>
	</v-dialog>
</template>

<script>
import Model from '../stations/ModelData';
import EditColumn from "@/ittijs/EditColumn";
import {getHiddenStationFields} from "@/views/stations/Index";

const model = new Model

export default {
	components: {
		EditColumn,
	},
	props: [
		'station',
		'session',
	],
	data(){
		return {
			open: false,
			model,
		}
	},
	methods: {
		getHiddenStationFields,
	},
}
</script>