<template>
	<span>{{ formatted }}</span>
</template>

<script>

const getNum = function(number) {
	if (typeof number === 'string') {
		return parseFloat(number);
	}
	else if (typeof number !== 'number') {
		return NaN;
	}
	return number;
};

export default {
	props: {
		decimals: [Number,String],
		number: [Number,String],
	},
	computed: {
		formatted(){
			const number = getNum(this.number);
			if (isNaN(number)) return '';
			const decimals = getNum(this.decimals);
			if (isNaN(decimals)) return number;
			return number.toFixed(decimals);
		},
	},
}
</script>