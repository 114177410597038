<template>
	<span>{{ options[value] }}</span>
</template>

<script>
import Model from './EnumValuesModel';

const model = new Model;

export default {
	props: [
		'code',
		'value',
	],
	data(){
		return {
			options: {}
		}
	},
	mounted(){
		model.getValues(this.code)
			.then(res=>{
				this.options = res;
			})
		;
	},
	watch: {
		code(code){
			model.getValues(code)
				.then(res=>{
					this.options = res;
				})
			;
		}
	},
}
</script>