<template>
	<v-menu left offset-x :close-on-content-click="false">
		<template #activator="{ on }">
			<v-btn fob small icon v-on="on" tabindex="-1">
				<v-icon v-if="warning" color="red">mdi-alert-outline</v-icon>
				<v-icon v-else>mdi-information-outline</v-icon>
			</v-btn>
		</template>
		<v-simple-table dense>
			<tbody>
				<!-- <tr><td>device_type_code:</td><td>{{ device.device_type_code }}</td></tr> -->
				<tr><td>Марка:</td><td>{{ device.m.brand }}</td></tr>
				<tr><td>Модел:</td><td>{{ device.m.model }}</td></tr>
				<!-- <tr><td>device_N:</td><td>{{ device.device_N }}</td></tr> -->
				<tr>
					<td><field-label name="plomba_N" :imodel="modelD" />:</td>
					<td><field-value name="plomba_N" :imodel="modelD" :value="device.d.plomba_N" /></td>
				</tr>
				<tr>
					<td><field-label name="mount_date" :imodel="modelD" />:</td>
					<td><field-value name="mount_date" :imodel="modelD" :value="device.d.mount_date" /></td>
				</tr>
				<tr>
					<td><field-label name="unmount_date" :imodel="modelD" />:</td>
					<td><field-value name="unmount_date" :imodel="modelD" :value="device.d.unmount_date" /></td>
				</tr>
				<tr>
					<td><field-label name="mount_value" :imodel="modelD" />:</td>
					<td><field-value name="mount_value" :imodel="modelD" :value="device.d.mount_value" /></td>
				</tr>
				<tr>
					<td><field-label name="unmount_value" :imodel="modelD" />:</td>
					<td><field-value name="unmount_value" :imodel="modelD" :value="device.d.unmount_value" /></td>
				</tr>
				<tr v-if="showValue2">
					<td><field-label name="unmount_value2" :imodel="modelD" />:</td>
					<td><field-value name="unmount_value2" :imodel="modelD" :value="device.d.unmount_value2" /></td>
				</tr>
				<tr>
					<td><field-label name="reinstall_date" :imodel="modelD" />:</td>
					<td :class="expired ? 'red--text font-weight-bold' : ''">
						<field-value name="reinstall_date" :imodel="modelD" :value="device.d.reinstall_date" />
					</td>
				</tr>
				<tr>
					<td><field-label name="wpl_id" :imodel="modelD" />:</td>
					<td><field-value name="wpl_id" :imodel="modelD" :value="device.d.wpl_id" /></td>
				</tr>
				<tr>
					<td><field-label name="room" :imodel="modelD" />:</td>
					<td><field-value name="room" :imodel="modelD" :value="device.d.room" /></td>
				</tr>
				<tr>
					<td><field-label name="radiator_factor" :imodel="modelD" />:</td>
					<td><field-value name="radiator_factor" :imodel="modelD" :value="device.d.radiator_factor" /></td>
				</tr>
				<tr>
					<td><field-label name="radiator_power" :imodel="modelD" />:</td>
					<td><field-value name="radiator_power" :imodel="modelD" :value="device.d.radiator_power" /></td>
				</tr>
				<tr>
					<td><field-label name="radiator_description" :imodel="modelD" />:</td>
					<td><field-value name="radiator_description" :imodel="modelD" :value="device.d.radiator_description" /></td>
				</tr>
				<tr>
					<td><field-label name="notes" :imodel="modelD" />:</td>
					<td><field-value name="notes" :imodel="modelD" :value="device.d.notes" /></td>
				</tr>
				<tr>
					<th colspan="2">За сесията</th>
				</tr>
				<tr>
					<td><field-label name="n_device_data.radiator_factor" :imodel="modelDD" />:</td>
					<td><field-value name="n_device_data.radiator_factor" :imodel="modelDD" :value="device.dd.radiator_factor" /></td>
				</tr>
				<tr>
					<td><field-label name="n_device_data.radiator_power" :imodel="modelDD" />:</td>
					<td><field-value name="n_device_data.radiator_power" :imodel="modelDD" :value="device.dd.radiator_power" /></td>
				</tr>
				<tr>
					<td><field-label name="n_device_data.radiator_description" :imodel="modelDD" />:</td>
					<td><field-value name="n_device_data.radiator_description" :imodel="modelDD" :value="device.dd.radiator_description" /></td>
				</tr>
<!--				<tr>-->
<!--					<td><field-label name="n_device_data.old_report" :imodel="modelDD" />:</td>-->
<!--					<td><field-value name="n_device_data.old_report" :imodel="modelDD" :value="device.dd.old_report" /></td>-->
<!--				</tr>-->
			</tbody>
		</v-simple-table>
	</v-menu>
</template>

<script>
import {isoToDate} from "@/ittijs/utils";
import FieldLabel from "@/ittijs/components/FieldLabel";
import FieldValue from "@/ittijs/components/FieldValue";
import modelClassD from "@/views/devices/Model";
import modelClassDD from "@/views/report/deviceData/Model";
import {deviceInGroup, DeviceTypeGroupTypes} from "@/ittijs/Nomenclatures";

const modelD = new modelClassD;
const modelDD = new modelClassDD;

export default {
	components: {FieldValue, FieldLabel},
	props: [
		'device'
	],
	data(){
		return {
			modelD,
			modelDD,
		}
	},
	computed: {
		showValue2(){
			try {
				return deviceInGroup(this.device.dd.device_type_code, DeviceTypeGroupTypes.Value2);
			}
			catch (e) {
				return false;
			}
		},
		expired(){
			const d = isoToDate(this.device.d.reinstall_date);
			if (d===null) return false;
			const today = new Date();
			today.setHours(0, 0, 0, 0);
			return d.getTime() <= today.getTime();
		},
		warning(){
			return this.expired; // add other warnings here
		},
	},
}
</script>