<template>
	<my-dialog title="Сметки" @open="onOpen" @close="onClose" ref="dialog" width="1100">
		<template #activator="scope">
			<slot name="activator" v-bind="scope" />
		</template>

		<div v-if="loading" class="text-center pa-4">
			<v-progress-circular indeterminate color="grey" />
		</div>

		<v-simple-table v-if="!loading" fixed-header ref="table" height="calc(100vh - 200px)">
			<thead>
			<tr>
				<th>Имот</th>
				<th>Адрес</th>
				<th>Име</th>
				<th v-if="izravnitelna">Фактурирано (лв)</th>
				<th v-if="izravnitelna">Разпределено (лв)</th>
				<th>Сметка (лв)</th>
				<th></th>
			</tr>
			</thead>
			<tbody>
			<tr v-for="(item, i) in items" :key="i">
				<td>
					{{item.imot_data.imot_N}}<br>
					{{item.imot_data.location}}
				</td>
				<td>{{item.imot_data.address}}</td>
				<td>{{item.imot_data.client_name}}</td>
				<td align="right" v-if="izravnitelna">{{item.imot_data.izr_amount_invoiced}}</td>
				<td align="right" v-if="izravnitelna">{{item.imot_data.izr_amount_razpr}}</td>
				<td align="right">{{ izravnitelna ? izrSmetka(item) : item.amount.total}}</td>
				<td>
					<download-btn
						label="Свали PDF"
						text
						:rest-service="()=>imodel.downloadPDFReport(session, item.imot_data.imot_id, item.imot_data.station_id)"
					/>
				</td>
			</tr>
			</tbody>
		</v-simple-table>

		<v-divider />

		<div class="d-flex pa-2">
			<download-btn
				label="Свали сметките за всички имоти"
				text
				:rest-service="()=>imodel.downloadAllPDFReports(session, station)"
			/>
			<v-spacer/>
			<v-btn text class="mr-2" @click="close">Затвори</v-btn>
		</div>

	</my-dialog>
</template>

<script>
import MyDialog from "@/ittijs/components/MyDialog";
import IModelMixin from "@/ittijs/IModelMixin";
import DownloadBtn from "@/ittijs/components/DownloadBtn";

export default {
	components: {DownloadBtn, MyDialog},
	mixins: [IModelMixin],
	props: [
		'session',
		'station',
		'izravnitelna',
	],
	data(){
		return {
			loading: false,
			listData: null,
			value: null,
		}
	},
	computed: {
		items(){
			return this.listData || [];
		},
	},
	methods: {
		izrSmetka(item){
			let sum = 0;
			sum += item.imot_data.izr_amount_razpr || 0;
			sum -= item.imot_data.izr_amount_invoiced || 0;
			return sum.toFixed(2);
		},
		async onOpen(){
			this.listData = null;
			this.loading = true;
			this.$refs.dialog.doOpen();
			this.listData = await this.imodel.getImotSmetki(this.session, this.station);
			this.loading = false;
		},
		onClose(){
			// todo
		},
		close(){
			this.$refs.dialog.doClose();
		},
		download(){
			return this.imodel.downloadAllPDFReports(this.session, this.station);
		},
	},
}
</script>