<template>
	<data-loader :watch="deviceId" v-if="deviceId" :callback="loadData">
		<template #default="{data}">
			<div v-if="data && (data.old || data.new)">
				<v-alert v-if="data.old" icon="mdi-home-import-outline" dense type="success">
					Заменя уред {{data.old?.device_N}} (<date-format :date="data.old?.unmount_date" />)
				</v-alert>
				<v-alert v-if="data.new" icon="mdi-home-export-outline" dense type="error">
					Заменен от уред {{data.new?.device_N}}  (<date-format :date="data.new?.mount_date" />)
				</v-alert>
			</div>
		</template>
	</data-loader>
</template>

<script>
import DataLoader from "@/ittijs/components/DataLoader.vue";
import IModelMixin from "@/ittijs/IModelMixin";
import DateFormat from "@/ittijs/components/DateFormat.vue";

export default {
	mixins: [IModelMixin],
	components : {DateFormat, DataLoader},
	props: {
		deviceId: {},
	},
	methods: {
		loadData() {
			return this.imodel.fetch('getReplacementInfo', {id: this.deviceId});
		}
	},
}
</script>