import {ITTIModelBase} from '../../ittijs/ITTIModel';
export default class extends ITTIModelBase {
	constructor() {
		super('ClientScreen');
	}
	async getBySessionStation(session, station){
		return await this.fetch('getBySessionStation', {session, station});
	}
	async getImotData(imot, session, station){
		return await this.fetch('getImotData', {imot, session, station});
	}
	addDeviceToSession(device, session){
		return this.fetch('addDeviceToSession', {device, session}, {});
	}
	getConfigSlujeben(session, station){
		return this.fetch('getConfigSlujeben', {session, station});
	}
	recalcSlujeben(session, station, data){
		return this.fetch('recalcSlujeben', {session, station}, data);
	}
	recalcConsumption(session, station){
		return this.fetch('recalcConsumption', {session, station}, {});
	}
	makeIzrPDFs(session, station){
		return this.fetch('makeIzrPDFs', {session, station}, {});
	}

	getMSRSList(station, session){
		return this.fetch('getMSRSList', {station, session});
	}
	updateMsrsShtrang(session, station, msrs, shtrangCorrection, deviceId){
		return this.fetch('updateMsrsShtrang', {session, station, msrs, shtrangCorrection, deviceId}, {});
	}

	getQMaxDevices(station, session){
		return this.fetch('getQMaxDevices', {station, session});
	}
	updateQMax(session, station, value){
		return this.fetch('updateQMax', {session, station, value}, {});
	}

	getVodaData(session, station) {
		return this.fetch('getVodaData', {session, station});
	}
	updateVodaZaRazpr(session, station, value){
		return this.fetch('updateVodaZaRazpr', {session, station, value}, {});
	}

	getHwM3ToMwhData(session, station) {
		return this.fetch('getHwM3ToMwhData', {session, station});
	}
	updateHwM3ToMwh(session, station, value) {
		return this.fetch('updateHwM3ToMwh', {session, station, value}, {});
	}

	getImotSmetki(session, station) {
		return this.fetch('getImotSmetki', {session, station});
	}
	downloadPDFReport(session, imot, station) {
		return this.fetch('downloadPDFReport', {session, imot, station});
	}
	downloadAllPDFReports(session, station) {
		return this.fetch('downloadAllPDFReports', {session, station});
	}
	addStationToSession(session, station) {
		return this.fetch('addStationToSession', {session, station});
	}
	removeStationFromSession(session, station) {
		return this.fetch('removeStationFromSession', {session, station});
	}
}