<!-- Add to or remove an imot from the last session -->
<template>
  <div>
    <confirm-dialog @confirm="remove" v-if="state === true">
      <template v-slot:activator="{attr,on}">
        <v-btn
          v-bind="attr"
          v-on="on"
          small
          color="red darken-2"
          dark
        ><v-icon left>mdi-delete-circle</v-icon>Премахни от последната сесия</v-btn>
      </template>
      Сигурни ли сте?
    </confirm-dialog>
    <v-btn
      small
      v-if="state === false"
      @click="add"
      color="primary darken-1"
    ><v-icon left>mdi-plus-circle</v-icon>Добави към последната сесия</v-btn>
  </div>
</template>

<script>
import IModelMixin from "@/ittijs/IModelMixin";
import ConfirmDialog from "@/ittijs/ConfirmDialog";

export default {
  components: {ConfirmDialog},
  mixins: [
    IModelMixin,
  ],
  props: {
    imotId: Number,
    stationId: Number,
  },
  data(){
    return {
      state: null, // can be null, true, or false
    }
  },
  mounted() {
    this.loadState();
  },
  watch: {
    imotId(){
      this.loadState();
    },
  },
  methods: {
    async loadState(){
      this.state = true;
      if (this.imotId > 0) {
        const state = await this.imodel.getLastSessionState(this.imotId, this.stationId);
        if (state === null || typeof state === 'boolean') {
          this.state = state;
        }
        else {
          this.state = null;
        }
      }
    },
    async add(){
      if (this.imotId > 0 && await this.imodel.lastSessionAdd(this.imotId, this.stationId)) {
        this.state = true;
      }
    },
    async remove(){
      if (this.imotId > 0 && await this.imodel.lastSessionRemove(this.imotId, this.stationId)) {
        this.state = false;
      }
    },
  },
}
</script>