<template>
	<div>
		<div class="pa-2">
			<p>Формат на CSV файла за импорт:</p>
			<table class="mytable" border="1" style="border-collapse: collapse" width="100%">
				<tr>
					<th>imot_N</th>
					<th>imot_N_alt</th>
					<th>address</th>
					<th>location</th>
					<th>volume</th>
					<th>client_N</th>
					<th>client_N_alt</th>
					<th>CONTRACT</th>
					<th>name</th>
					<th>phone</th>
					<th>email</th>
					<th>imot_type</th>
					<th>client_type</th>
				</tr>
				<tr>
					<td>&nbsp;</td>
					<td></td>
					<td></td>
					<td></td>
					<td></td>
					<td></td>
					<td></td>
					<td class="pa-1 text-center">(за София)</td>
					<td></td>
					<td></td>
					<td></td>
					<td class="pa-1">
						1 - Жилищен<br/>
						2 - Обществен<br/>
						3 - Друг<br/>
						(ако липсва: 1)
					</td>
					<td class="pa-1">
						1 - Физ. лице<br/>
						2 - Юрид. лице<br/>
						3 - Държавен/Общински<br/>
						(ако липсва: 1)
					</td>
				</tr>
			</table>
			<v-file-input required label="CSV файл" accept=".csv" v-model="file" />
		</div>
		<v-divider />
		<v-toolbar dense flat>
			<v-spacer />
			<v-btn :disabled="loading" :loading="loading" text @click="onSubmit">Импорт</v-btn>
		</v-toolbar>
	</div>
</template>

<script>
import IModelMixin from "@/ittijs/IModelMixin";
import ThrowMeMixin from "@/ittijs/ThrowMeMixin";

export default {
	mixins: [IModelMixin, ThrowMeMixin],
	props: [
		'stationId',
	],
	data(){
		return {
			file: null,
			loading: false,
			progress: null,
		}
	},
	methods: {
		async onSubmit(){
			if (this.file) {
				this.progress = 0;
				this.loading = true;
				const p = this.imodel.importImoti(this.stationId, this.file, function(evt){
					if (evt.lengthComputable && evt.total !== 0) {
						this.progress = evt.loaded * 100 / evt.total;
					}
					else {
						this.progress = null;
					}
				});

				try {
					await p.promise;
					this.file = null;
					this.$emit('imported');
				}
				catch (err) {
					if (err.response) {
						this.throwMe(err.response);
					}
					throw err;
				}
				finally {
					this.progress = null;
					this.loading = false;
				}
			}
		},
	},
}
</script>

<style scoped>
table.mytable > * > * > * {
	padding: 4px;
}
</style>